import clsx from 'clsx';
import React, { FC, forwardRef, InputHTMLAttributes } from 'react';
import { FieldError } from 'react-hook-form';

import styles from './Input.module.scss';

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
  label: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  srLabel?: boolean;
  error?: FieldError | string;
  prefix?: React.ReactNode | string;
  suffix?: React.ReactNode | string;
  helperText?: string;
  className?: string;
}

const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      type = 'text',
      onChange,
      srLabel = false,
      error,
      placeholder = '',
      prefix,
      disabled = false,
      suffix,
      helperText,
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={clsx(styles.base, className)} data-ui="input">
        <label>
          <span
            className={clsx(styles.label, {
              'sr-only': srLabel,
            })}
            data-ui="input-label"
          >
            {label}
          </span>
          <div className={styles.wrapper}>
            {prefix && (
              <div className={styles.prefix} data-ui="input-prefix">
                {prefix}
              </div>
            )}
            <input
              disabled={disabled}
              onChange={onChange}
              placeholder={placeholder}
              ref={ref}
              type={type}
              {...props}
            />
            {suffix && (
              <div className={styles.suffix} data-ui="input-suffix">
                {suffix}
              </div>
            )}
          </div>
        </label>
        {helperText && <p className={styles.helper}>{helperText}</p>}
        {error && (
          <p className={styles.error}>
            {typeof error === 'object' ? error.message : error}
          </p>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';

export { Input };
