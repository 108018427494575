import type { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface AddBaseCollectiveOrgRequest {
  /** @format double */
  minimumWage?: number | null;
  /** @format int32 */
  organizationKey: number;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  recruitmentPercent: number;
}

export interface AdminPlayerDealResponse {
  agent?: AgentResponse;
  /** @format double */
  amount?: number | null;
  /** @format int64 */
  collectiveGroupKey?: number | null;
  collectiveGroupName?: string | null;
  company?: NilCompanyResponse;
  /** @format date-time */
  date?: string | null;
  detailUrl?: string | null;
  /** @format int64 */
  key: number;
  person?: PlayerResponse;
  sourceUrl?: string | null;
  verified: boolean;
}

export interface AdminPlayerDealResponsePagedData {
  list?: AdminPlayerDealResponse[];
  pagination?: Pagination;
}

export interface AgentContactInformationRequest {
  agencyName?: string | null;
  email?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
}

export interface AgentContactInformationResponse {
  agencyName?: string | null;
  email?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
}

export interface AgentRequest {
  company?: string | null;
  /** @format int64 */
  key?: number | null;
  /** @minLength 1 */
  name: string;
}

export interface AgentResponse {
  company?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
}

export interface AgentResponsePagedData {
  list?: AgentResponse[];
  pagination?: Pagination;
}

export enum AnnouncementImageGraphicType {
  Commitment = 'Commitment',
  Decommitment = 'Decommitment',
  TopSchools = 'TopSchools',
  DecisionDate = 'DecisionDate',
  OfficialVisit = 'OfficialVisit',
  Offers = 'Offers',
}

export interface AnnouncementImageRequestResponse {
  additionalInformation?: string | null;
  artistRequested: boolean;
  completedFiles?: FileResponse[] | null;
  /** @format date-time */
  dateOfRelease?: string | null;
  /** @format int64 */
  dateRequested: number;
  emailAddress?: string;
  files?: FileResponse[] | null;
  graphicType: AnnouncementImageGraphicType;
  /** @format int64 */
  key: number;
  person?: SimplePersonResponse;
  /** @format int64 */
  personKey: number;
  schools?: AnnouncementImageSchoolResponse[] | null;
  showOnProfileRequested: boolean;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey: number;
  status: AnnouncementImageStatus;
}

export interface AnnouncementImageRequestResponsePagedData {
  list?: AnnouncementImageRequestResponse[];
  pagination?: Pagination;
}

export interface AnnouncementImageSchoolResponse {
  assetUrl?: string | null;
  /** @format int64 */
  key: number;
  /** @format int64 */
  organizationKey: number;
  organizationName?: string;
}

export enum AnnouncementImageStatus {
  Requested = 'Requested',
  InProgress = 'InProgress',
  Delivered = 'Delivered',
  Rejected = 'Rejected',
}

/** Specifies the kind of asset and Asset object is. */
export enum AssetClasses {
  Unknown = 'Unknown',
  Image = 'Image',
  Video = 'Video',
}

export interface AssetResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export interface AthleteActivationRequestRequest {
  comment?: string | null;
  emailAddress?: string | null;
  instagramHandle?: string | null;
  /** @format int64 */
  personKey: number;
  tiktokHandle?: string | null;
  twitterHandle?: string | null;
  /** @format int64 */
  userKey?: number | null;
}

export interface AthleteActivationRequestResponse {
  comment?: string | null;
  emailAddress?: string;
  instagramHandle?: string | null;
  /** @format int64 */
  key: number;
  person?: SimplePersonResponse;
  /** @format int64 */
  personKey: number;
  tiktokHandle?: string | null;
  twitterHandle?: string | null;
}

export interface AttributeResponse {
  abbr?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
}

export interface BaseCollectiveOrgWithOrgResponse {
  /** @format int64 */
  baseCollectiveKey: number;
  conference?: ConferenceResponse;
  /** @format int64 */
  key: number;
  /** @format double */
  minimumWage?: number | null;
  /** @format int32 */
  modifier: number;
  /** @format double */
  modifierValue: number;
  organization: OrganizationResponse;
  /** @format int32 */
  organizationKey: number;
  /** @format double */
  recruitingPercent: number;
  stale: boolean;
  /** @format int32 */
  tierCount: number;
}

export interface BaseCollectiveOrgWithTiersResponse {
  /** @format int64 */
  baseCollectiveKey: number;
  /** @format double */
  baseValue: number;
  conference?: ConferenceResponse;
  /** @format int64 */
  key: number;
  /** @format double */
  minimumWage?: number | null;
  /** @format int32 */
  modifier: number;
  /** @format double */
  modifierValue: number;
  organization: OrganizationResponse;
  /** @format int32 */
  organizationKey: number;
  /** @format double */
  recruitingPercent: number;
  sport?: SportResponseRecord;
  stale: boolean;
  /** @format int32 */
  tierCount: number;
  tiers: CollectiveTierAdminResponse[];
  /** @format int32 */
  year: number;
}

export interface BaseCollectiveResponse {
  /** @format int32 */
  baseValue: number;
  /** @format int64 */
  key: number;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
}

export interface BaseCollectiveResponsePagedData {
  list?: BaseCollectiveResponse[];
  pagination?: Pagination;
}

export interface BaseCollectiveWithOrgsResponse {
  /** @format int32 */
  baseValue: number;
  /** @format int64 */
  key: number;
  organizations?: BaseCollectiveOrgWithOrgResponse[];
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
}

export enum Category {
  Undetermined = 'Undetermined',
  Highlights = 'Highlights',
  Training = 'Training',
  Experts = 'Experts',
  Conversations = 'Conversations',
  Camp = 'Camp',
}

export interface Children {
  data?: Data[] | null;
}

export interface CityResponseTest {
  /** @format int32 */
  key: number;
  name?: string;
  postalCode?: string;
}

export interface ClaimRequest {
  /** @minLength 1 */
  accessToken: string;
  /** @format int64 */
  personKey: number;
}

export enum ClassYear {
  Unknown = 'Unknown',
  Freshman = 'Freshman',
  RedShirtFreshman = 'RedShirt_Freshman',
  Sophomore = 'Sophomore',
  RedShirtSophomore = 'RedShirt_Sophomore',
  Junior = 'Junior',
  RedShirtJunior = 'RedShirt_Junior',
  Senior = 'Senior',
  RedShirtSenior = 'RedShirt_Senior',
}

export interface CollectiveTierAdminPersonResponse {
  /** @format int32 */
  classYear: number;
  defaultAsset?: ImageResponseTest;
  firstName?: string;
  fullName?: string;
  hometown?: CityResponseTest;
  /** @format int32 */
  jerseyNumber?: number | null;
  /** @format int64 */
  key: number;
  knownAsName?: string | null;
  lastName?: string;
  /** @format int64 */
  latestTotalValuation?: number | null;
  /** @format double */
  nilAdjustedRosterValuation: number;
  /** @format double */
  nilAdjustedValuation: number;
  position?: PositionResponse;
  /** @format int64 */
  rankingValue?: number | null;
  slug?: string;
}

export interface CollectiveTierAdminResponse {
  /** @format int64 */
  collectiveKey: number;
  drafts?: CollectiveTierValuationDraftAdminResponse[];
  /** @format int64 */
  key: number;
  name?: string;
  /** @format int32 */
  order: number;
  /** @format double */
  percent: number;
  /** @format int32 */
  personLimit: number;
  stale: boolean;
}

export interface CollectiveTierResponse {
  /** @format int64 */
  collectiveKey: number;
  /** @format int64 */
  key: number;
  name?: string;
  /** @format int32 */
  order: number;
  /** @format double */
  percent: number;
  /** @format int32 */
  personLimit: number;
  stale: boolean;
}

export interface CollectiveTierResponseRenovateResponse {
  errors?: string[];
  resource?: CollectiveTierResponse;
  success: boolean;
}

export interface CollectiveTierResponseRenovateResponsePagedData {
  list?: CollectiveTierResponseRenovateResponse[];
  pagination?: Pagination;
}

export enum CollectiveTierTypes {
  Roster = 'Roster',
  Recruiting = 'Recruiting',
}

export interface CollectiveTierValuationDraftAdminResponse {
  /** @format double */
  actualValue?: number | null;
  classYear?: ClassYear;
  /** @format int64 */
  collectiveTierKey: number;
  /** @format int32 */
  createdTimestamp: number;
  isRecruit: boolean;
  isWalkOn: boolean;
  /** @format int64 */
  key: number;
  /** @format int32 */
  order: number;
  person?: CollectiveTierAdminPersonResponse;
  /** @format int64 */
  personSportKey: number;
  /** @format int64 */
  personSportOrganizationKey?: number | null;
  /** @format double */
  projectedValue: number;
  /** @format int64 */
  recruitmentInterestKey?: number | null;
  stale: boolean;
  /** @format int32 */
  updatedTimestamp?: number | null;
}

export interface CollectiveTierValuationDraftResponse {
  /** @format double */
  actualValue?: number | null;
  classYear?: ClassYear;
  /** @format int64 */
  collectiveTierKey: number;
  /** @format int32 */
  createdTimestamp: number;
  isRecruit: boolean;
  isWalkOn: boolean;
  /** @format int64 */
  key: number;
  /** @format int32 */
  order: number;
  /** @format int64 */
  personSportKey: number;
  /** @format int64 */
  personSportOrganizationKey?: number | null;
  /** @format double */
  projectedValue: number;
  /** @format int64 */
  recruitmentInterestKey?: number | null;
  stale: boolean;
  /** @format int32 */
  updatedTimestamp?: number | null;
}

export interface CollectiveTierValuationDraftResponseRenovateResponse {
  errors?: string[];
  resource?: CollectiveTierValuationDraftResponse;
  success: boolean;
}

export interface CollectiveTierValuationDraftResponseRenovateResponsePagedData {
  list?: CollectiveTierValuationDraftResponseRenovateResponse[];
  pagination?: Pagination;
}

export enum ConferenceGroups {
  Unknown = 'Unknown',
  PowerFive = 'PowerFive',
  GroupOfFive = 'GroupOfFive',
}

export interface ConferenceResponse {
  abbreviation?: string | null;
  asset?: KeyUrlBase;
  /** @format int64 */
  assetKey?: number | null;
  assetRes?: AssetResponse;
  isGroupOfFive: boolean;
  isPowerFive: boolean;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface ContactInformationRequest {
  email?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
}

export interface CreateBaseCollectiveRequest {
  /** @format int32 */
  baseValue: number;
  /** @format int32 */
  sportKey: number;
  /** @format int32 */
  year: number;
}

export interface Cursors {
  after?: string | null;
  before?: string | null;
}

export interface Data {
  caption?: string | null;
  children?: Children;
  id?: string | null;
  media_type?: string | null;
  media_url?: string | null;
  /** @format date-time */
  timestamp: string;
  username?: string | null;
}

export interface DeleteResponse {
  clientError: boolean;
  errors?: string[];
  success: boolean;
}

export type File = object;

export interface FileResponse {
  key?: string;
  /** @format date-time */
  lastModifiedDate?: string | null;
  name?: string | null;
  /** @format int64 */
  personKey?: number | null;
  /** @format int64 */
  tag?: number | null;
}

export interface ImageResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export interface ImageResponseTest {
  /** AltText of image */
  altText?: string | null;
  /** Caption of image */
  caption?: string | null;
  category?: Category;
  /** Description of image */
  description?: string | null;
  /** Domain of image */
  domain?: string;
  /**
   * Height of image
   * @format int32
   */
  height?: number | null;
  /**
   * Key of term
   * @format int64
   */
  key: number;
  /** Source of image */
  source?: string;
  /** Title/Name of image */
  title?: string | null;
  /**
   * Width of image
   * @format int32
   */
  width?: number | null;
}

export interface ImportInflcrFailureResponse {
  /** @format uuid */
  inflcrKey: string;
  message?: string | null;
}

export interface ImportInflcrFailureResponsePagedData {
  list?: ImportInflcrFailureResponse[];
  pagination?: Pagination;
}

export interface ImportInflcrSuccessResponse {
  /** @format uuid */
  inflcrKey: string;
  /** @format int64 */
  personKey: number;
}

export interface ImportInflcrSuccessResponsePagedData {
  list?: ImportInflcrSuccessResponse[];
  pagination?: Pagination;
}

export interface ImportResponse {
  failures?: ImportInflcrFailureResponsePagedData;
  successes?: ImportInflcrSuccessResponsePagedData;
}

export interface Int64NullableValueVisibility {
  /** @format int64 */
  value?: number | null;
  visibility: Visibility;
}

export interface Int64ValueVisibility {
  /** @format int64 */
  value: number;
  visibility: Visibility;
}

export interface KeyUrlBase {
  fullName?: string;
  /** @format int64 */
  key: number;
  slug?: string | null;
  url?: string | null;
}

export interface MediaResponse {
  data?: Data[] | null;
  paging?: Paging;
}

export interface MoneyWantedRequest {
  agentInfo?: AgentContactInformationRequest;
  /** @format double */
  annualWanted: number;
  /** @format int64 */
  personKey: number;
}

export interface MoneyWantedResponse {
  agentInfo?: AgentContactInformationResponse;
  /** @format double */
  annualMoneyWanted: number;
  /** @format date-time */
  date: string;
  /** @format int64 */
  personKey: number;
  player?: SmallPlayerResponse;
}

export interface MoneyWantedResponsePagedData {
  list?: MoneyWantedResponse[];
  pagination?: Pagination;
}

export enum NILDealStatus {
  Submitted = 'Submitted',
  PendingInformation = 'PendingInformation',
  Approved = 'Approved',
  Denied = 'Denied',
}

export interface NilAverageValuationReportResponse {
  /** @format double */
  averageValue?: number | null;
  conference?: ConferenceResponse;
  conferenceGroup?: ConferenceGroups;
  /** @format int32 */
  conferenceKey?: number | null;
  /** @format date-time */
  date: string;
  /** @format int64 */
  key: number;
  /** @format double */
  medianValue?: number | null;
  organization?: OrganizationResponse;
  /** @format int32 */
  organizationKey?: number | null;
  reportType: NilAverageValuationReportType;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey?: number | null;
}

export enum NilAverageValuationReportType {
  Unknown = 'Unknown',
  Collective = 'Collective',
  TotalValue = 'TotalValue',
  RosterValue = 'RosterValue',
  BrandValue = 'BrandValue',
  Sport = 'Sport',
  ConferenceGroup = 'ConferenceGroup',
  Conference = 'Conference',
  Organization = 'Organization',
}

export interface NilAveragesReportResponse {
  basketball?: NilSportAveragesReportResponse;
  football?: NilSportAveragesReportResponse;
  groupOfFiveCollective?: NilAverageValuationReportResponse;
  groupOfFiveConferencesCollective?: NilAverageValuationReportResponse[] | null;
  powerFiveCollective?: NilAverageValuationReportResponse;
  powerFiveConferencesCollective?: NilAverageValuationReportResponse[] | null;
}

export interface NilCompanyAdminResponse {
  defaultAsset?: ImageResponse;
  /** @format int64 */
  defaultAssetKey?: number | null;
  details?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
  url?: string | null;
}

export interface NilCompanyAdminResponsePagedData {
  list?: NilCompanyAdminResponse[];
  pagination?: Pagination;
}

export interface NilCompanyPartnerRequest {
  /** @format int64 */
  defaultAssetKey?: number | null;
  details?: string | null;
  /** @format int64 */
  key?: number | null;
  /** @minLength 1 */
  name: string;
  url?: string | null;
}

export interface NilCompanyResponse {
  assetUrl?: string | null;
  details?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
  url?: string | null;
}

export interface NilComplianceResponse {
  currentRules?: string | null;
  governingRuleLabel?: string | null;
  governingRuleUrl?: string | null;
  /** @format int64 */
  key: number;
  monetizationAllowed?: boolean | null;
  organizationType: OrganizationType;
  state?: StateResponseRecord;
  /** @format int32 */
  stateKey: number;
}

export interface NilDashboardSummaryItemResponse {
  /** @format double */
  exposureRating?: number | null;
  /** @format double */
  influenceRating?: number | null;
  /** @format int64 */
  instagramFollowers?: number | null;
  nilStatus: NilStatuses;
  /** @format double */
  performanceRating?: number | null;
  /** @format double */
  pieAverage?: number | null;
  /** @format int64 */
  tikTokFollowers?: number | null;
  /** @format int64 */
  twitterFollowers?: number | null;
  /** @format int64 */
  valuation?: number | null;
}

export interface NilDashboardSummaryResponse {
  collegeOverallRankingSummary?: NilDashboardSummaryItemResponse;
  collegePositionRankingSummary?: NilDashboardSummaryItemResponse;
  highSchoolOverallRankingSummary?: NilDashboardSummaryItemResponse;
  highSchoolTopRankingSummary?: NilDashboardSummaryItemResponse;
  /** @format int64 */
  personKey: number;
  personalSummary?: NilDashboardSummaryItemResponse;
  position?: PositionResponse;
}

export interface NilDashboardValuationBreakdownResponse {
  /** @format int64 */
  brandValue: number;
  /** @format double */
  exposureRating?: number | null;
  /** @format double */
  influenceRating?: number | null;
  nilStatus: NilStatuses;
  /** @format int64 */
  nilValue?: number | null;
  /** @format double */
  performanceRating?: number | null;
  /** @format int64 */
  personKey: number;
  pickedOrganization?: OrganizationResponse;
  pickedValuation?: NilDashboardValuationBreakdownResponse;
  /** @format double */
  pieAverage?: number | null;
  playerServiceRatingCount?: PlayerServiceRatingCountResponse;
  /** @format int64 */
  rosterValue?: number | null;
  /** @format int64 */
  valuation?: number | null;
}

export interface NilDashboardValuationBreakdownResponsePagedData {
  list?: NilDashboardValuationBreakdownResponse[];
  pagination?: Pagination;
}

export interface NilDealSubmissionResponse {
  agentName?: string | null;
  /** @format double */
  amount?: number | null;
  collectiveGroupName?: string | null;
  companyName?: string | null;
  /** @format date-time */
  date?: string | null;
  files?: FileResponse[] | null;
  /** @format int64 */
  key?: number | null;
  /** @format int64 */
  personKey: number;
  personName?: string;
  /** The slug of the person */
  personSlug?: string;
  public: boolean;
  sourceUrl?: string | null;
  status: NILDealStatus;
  statusReason?: string | null;
  term: TermAmount;
}

export interface NilDealSubmissionResponsePagedData {
  list?: NilDealSubmissionResponse[];
  pagination?: Pagination;
}

export interface NilErrorResponse {
  /** @format date-time */
  errorAt?: string | null;
  errorMessage?: string | null;
  errorType?: ScrapeErrorType;
  firstName?: string;
  instagramHandle?: string | null;
  lastName?: string;
  /** @format int64 */
  personKey: number;
  socialType: SocialType;
  tikTokHandle?: string | null;
  twitterHandle?: string | null;
}

export interface NilErrorResponsePagedData {
  list?: NilErrorResponse[];
  pagination?: Pagination;
}

export interface NilIndustryAverageItemResponse {
  label?: string;
  /** @format int64 */
  valuation: number;
}

export interface NilIndustryAverageRecruitmentYearResponse {
  blueChips?: NilIndustryAverageItemResponse;
  fiveStars?: NilIndustryAverageItemResponse;
  fourStars?: NilIndustryAverageItemResponse;
  /** @format int32 */
  year: number;
}

export interface NilIndustryAverageResponse {
  college?: NilIndustryAverageItemResponse[];
  recruits?: NilIndustryAverageRecruitmentYearResponse[];
  sport?: SportResponseRecord;
}

export interface NilSportAveragesReportResponse {
  groupOfFiveConferencesRosterValue?:
    | NilAverageValuationReportResponse[]
    | null;
  groupOfFiveConferencesTotalValue?: NilAverageValuationReportResponse[] | null;
  groupOfFiveOrganizationsRosterValue?:
    | NilAverageValuationReportResponse[]
    | null;
  groupOfFiveOrganizationsTotalValue?:
    | NilAverageValuationReportResponse[]
    | null;
  powerFiveConferencesRosterValue?: NilAverageValuationReportResponse[] | null;
  powerFiveConferencesTotalValue?: NilAverageValuationReportResponse[] | null;
  powerFiveOrganizationsRosterValue?:
    | NilAverageValuationReportResponse[]
    | null;
  powerFiveOrganizationsTotalValue?: NilAverageValuationReportResponse[] | null;
}

export enum NilStatuses {
  Normal = 'Normal',
  Pending = 'Pending',
  Suspended = 'Suspended',
  Private = 'Private',
  Hidden = 'Hidden',
  Show = 'Show',
  Frozen = 'Frozen',
  Final = 'Final',
}

export interface NilValuationAnomalyResponse {
  current?: NilDashboardValuationBreakdownResponse;
  organization?: OrganizationResponse;
  person?: SimplePersonResponse;
  previous?: NilDashboardValuationBreakdownResponse;
  sport?: SportResponseRecord;
  /** @format int64 */
  valuationDifference: number;
}

export interface NilValuationAnomalyResponsePagedData {
  list?: NilValuationAnomalyResponse[];
  pagination?: Pagination;
}

export interface NilValuationBoostResponse {
  defaultSport?: SportResponseRecord;
  /** @format double */
  nilAdjustedRosterValuation: number;
  /** @format double */
  nilAdjustedValuation: number;
  nilValue?: ValuationResponse;
  organization?: OrganizationResponse;
  person?: SimplePersonResponse;
  /** @format int64 */
  personKey: number;
  position?: PositionResponse;
}

export interface NilValuationBoostResponsePagedData {
  list?: NilValuationBoostResponse[];
  pagination?: Pagination;
}

export enum NilValueBoostFunctionType {
  Flat = 'Flat',
  StairStep = 'StairStep',
  Percent = 'Percent',
}

export interface NotsbgResponse {
  /** @format int64 */
  key: number;
  organizationType: OrganizationType;
  rankingType: RankingType;
  sport?: SportResponseRecord;
  /** @format int32 */
  year?: number | null;
}

export interface NotsbgResponsePagedData {
  list?: NotsbgResponse[];
  pagination?: Pagination;
}

export interface NotsrbResponse {
  /** @format int64 */
  endingRank?: number | null;
  /** @format double */
  endingRating?: number | null;
  /** @format double */
  endingValue: number;
  functionType: NilValueBoostFunctionType;
  /** @format int64 */
  key: number;
  /** @format int64 */
  nilOrgTypSportBoostGroupKey: number;
  /** @format double */
  stairStepValue?: number | null;
  /** @format int64 */
  startingRank?: number | null;
  /** @format double */
  startingRating?: number | null;
  /** @format double */
  startingValue: number;
}

export interface NotsrbResponseNotsbgResponseRelatedPagedData {
  list?: NotsrbResponse[];
  pagination?: Pagination;
  relatedModel?: NotsbgResponse;
}

export interface NpbgResponse {
  /** @format int64 */
  key: number;
  /** @format int64 */
  nilOrgTypeSportBoostGroupKey: number;
  position?: PositionResponse;
}

export interface NpbgResponseNotsbgResponseRelatedPagedData {
  list?: NpbgResponse[];
  pagination?: Pagination;
  relatedModel?: NotsbgResponse;
}

export interface NprbResponse {
  /** @format int64 */
  endingRank?: number | null;
  /** @format double */
  endingRating?: number | null;
  /** @format double */
  endingValue: number;
  functionType: NilValueBoostFunctionType;
  /** @format int64 */
  key: number;
  /** @format int64 */
  nilPositionBoostGroupKey: number;
  /** @format double */
  stairStepValue?: number | null;
  /** @format int64 */
  startingRank?: number | null;
  /** @format double */
  startingRating?: number | null;
  /** @format double */
  startingValue: number;
}

export interface NprbResponseNpbgResponseRelatedPagedData {
  list?: NprbResponse[];
  pagination?: Pagination;
  relatedModel?: NpbgResponse;
}

export interface OrgSportExposureOverrideResponse {
  /** @format int64 */
  key: number;
  organization?: OrganizationResponse;
  /** @format double */
  override: number;
  sport?: SportResponseRecord;
}

export interface OrgSportExposureOverrideResponseOseoFilterResponseRelatedPagedData {
  list?: OrgSportExposureOverrideResponse[];
  pagination?: Pagination;
  relatedModel?: OseoFilterResponse;
}

export interface OrganizationResponse {
  abbreviation?: string | null;
  assetUrl?: string | null;
  defaultAsset?: AssetResponse;
  division?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  knownAs?: string | null;
  mascot?: string | null;
  name?: string;
  orgType?: string;
  orgTypeEnum: OrganizationType;
  primaryColor?: string | null;
  siteKeys?: number[] | null;
  slug?: string | null;
  urlSlug?: string | null;
}

export enum OrganizationType {
  Unknown = 'Unknown',
  HighSchool = 'HighSchool',
  JuniorCollege = 'JuniorCollege',
  College = 'College',
  Professional = 'Professional',
  PrepSchool = 'PrepSchool',
  Developmental = 'Developmental',
}

export interface OrganizationTypeVamcResponse {
  /** @format int64 */
  count: number;
  orgType: OrganizationType;
}

export interface OrganizationTypeVamcResponsePagedData {
  list?: OrganizationTypeVamcResponse[];
  pagination?: Pagination;
}

export interface OrganizationVamcResponse {
  /** @format int64 */
  count: number;
  org?: OrganizationResponse;
}

export interface OrganizationVamcResponsePagedData {
  list?: OrganizationVamcResponse[];
  pagination?: Pagination;
}

export interface OseoFilterResponse {
  organization?: OrganizationResponse;
  sport?: SportResponseRecord;
}

export interface Pagination {
  /** @format int32 */
  count: number;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  itemsPerPage: number;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  /** @format int32 */
  pageCount: number;
}

export interface Paging {
  cursors?: Cursors;
  next?: string | null;
}

export interface PersonNilValuationDraftResponse {
  boostSummary?: any;
  /** @format date-time */
  date: string;
  /** @format double */
  exposureScore?: number | null;
  /** @format int64 */
  exposureValue: number;
  groupOrganizationType?: OrganizationType;
  groupPositionType?: PositionType;
  /** @format int64 */
  groupRank?: number | null;
  /** @format int32 */
  groupSportKey?: number | null;
  /** @format int32 */
  groupYear?: number | null;
  /** @format double */
  influenceScore?: number | null;
  /** @format int64 */
  influenceValue: number;
  /** @format int64 */
  key: number;
  /** @format double */
  nilAdjustedRosterValuation: number;
  /** @format double */
  nilAdjustedValuation: number;
  nilStatus: NilStatuses;
  /** @format int64 */
  nilValue?: number | null;
  /** @format double */
  performanceScore?: number | null;
  /** @format int64 */
  performanceValue: number;
  person?: PlayerResponse;
  /** @format int64 */
  personKey: number;
  /** @format int64 */
  personSportOrganizationKey?: number | null;
  /** @format int64 */
  rank?: number | null;
  /** @format int64 */
  recruitmentKey?: number | null;
  /** @format int64 */
  rosterValue?: number | null;
  /** @format double */
  score?: number | null;
  stale: boolean;
  /** @format int64 */
  totalValue: number;
}

export interface PersonNilValuationDraftResponsePagedData {
  list?: PersonNilValuationDraftResponse[];
  pagination?: Pagination;
}

export interface PersonSettingsRequest {
  agentInfo?: AgentContactInformationRequest;
  birthDate?: Int64ValueVisibility;
  cellNumber?: StringValueVisibility;
  email?: StringValueVisibility;
  instagram?: SocialRequest;
  parentInfo?: ContactInformationRequest;
  secondaryParentInfo?: ContactInformationRequest;
  tikTok?: SocialRequest;
  twitter?: SocialRequest;
}

export interface PersonSettingsResponse {
  birthDate?: Int64NullableValueVisibility;
  cellNumber?: StringValueVisibility;
  email?: StringValueVisibility;
  instagramHandle?: string | null;
  tikTokHandle?: string | null;
  twitterHandle?: string | null;
}

export interface PersonSocialMetricResponse {
  /** @format int64 */
  followers: number;
  /** @format int64 */
  following: number;
  handshake: boolean;
  /** @format int64 */
  key: number;
  /** @format int64 */
  mediaCount: number;
  /** @format int64 */
  personKey: number;
  socialType: SocialType;
  verified: boolean;
}

export interface PersonSocialMetricResponsePagedData {
  list?: PersonSocialMetricResponse[];
  pagination?: Pagination;
}

export interface PersonSocialMetricResponseRenovateResponse {
  errors?: string[];
  resource?: PersonSocialMetricResponse;
  success: boolean;
}

export interface PersonSocialMetricResponseRenovateResponsePagedData {
  list?: PersonSocialMetricResponseRenovateResponse[];
  pagination?: Pagination;
}

export interface PlayerNilDealRequest {
  /** @format int64 */
  agentKey?: number | null;
  /** @format double */
  amount?: number | null;
  /** @format int64 */
  collectiveGroupKey?: number | null;
  /** @format int64 */
  companyKey?: number | null;
  /** @format date-time */
  date?: string | null;
  detailUrl?: string | null;
  /** @format int64 */
  key?: number | null;
  /** @format int64 */
  personKey: number;
  sourceUrl?: string | null;
  verified: boolean;
}

export interface PlayerResponse {
  /** @format int32 */
  age?: number | null;
  athleteVerified: boolean;
  classRank?: string | null;
  /** @format int32 */
  classYear?: number | null;
  /** @format date-time */
  dateOfBirth?: string | null;
  defaultAsset?: AssetResponse;
  defaultSport?: AttributeResponse;
  division?: string | null;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  firstName?: string;
  fullName?: string;
  height?: string | null;
  highSchool?: OrganizationResponse;
  highSchoolName?: string | null;
  hometown?: AttributeResponse;
  /** @format int32 */
  jerseyNumber?: number | null;
  /** @format int64 */
  key: number;
  lastName?: string;
  position?: AttributeResponse;
  prospectVerified: boolean;
  /** @format int64 */
  recruitmentKey?: number | null;
  slug?: string;
  state?: AttributeResponse;
  /** @format double */
  weight?: number | null;
}

export interface PlayerServiceRatingCountResponse {
  on3: boolean;
  /** @format int32 */
  count: number;
  espn: boolean;
  rivals: boolean;
  tfs: boolean;
}

export interface PositionResponse {
  abbreviation?: string | null;
  /** @format int32 */
  key: number;
  name?: string;
  positionType: PositionType;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey?: number | null;
}

export enum PositionType {
  Player = 'Player',
  Coach = 'Coach',
  Operations = 'Operations',
}

export interface PositionVamcResponse {
  /** @format int64 */
  count: number;
  position?: PositionResponse;
}

export interface PositionVamcResponsePagedData {
  list?: PositionVamcResponse[];
  pagination?: Pagination;
}

export interface ProblemDetails {
  detail?: string | null;
  instance?: string | null;
  /** @format int32 */
  status?: number | null;
  title?: string | null;
  type?: string | null;
  [key: string]: any;
}

export interface ProcessRankingResponse {
  /** @format int32 */
  groupRanked: number;
  /** @format int32 */
  overallRanked: number;
  /** @format int32 */
  processed: number;
  /** @format date-time */
  ratingDate: string;
  /** @format int32 */
  reset: number;
  /** @format int32 */
  submitted: number;
  /** @format int32 */
  total: number;
}

export interface PublishRankingResponse {
  /** @format int32 */
  legacyPublishedCount: number;
  /** @format int32 */
  legacyPublishedRemoved: number;
  /** @format int32 */
  previousLinked: number;
  /** @format int32 */
  publishedCount: number;
  /** @format int32 */
  publishedRemoved: number;
  /** @format date-time */
  ratingDate: string;
}

export interface RankingStarsVamcResponse {
  /** @format int64 */
  count: number;
  impact: boolean;
  /** @format int32 */
  stars?: number | null;
}

export interface RankingStarsVamcResponsePagedData {
  list?: RankingStarsVamcResponse[];
  pagination?: Pagination;
}

export enum RankingType {
  Player = 'Player',
  AllTimePlayer = 'AllTimePlayer',
  TransferPortal = 'TransferPortal',
  AllTimeTransferPortal = 'AllTimeTransferPortal',
  Draft = 'Draft',
  AllTimeDraft = 'AllTimeDraft',
  Organization = 'Organization',
  AllTimeOrganization = 'AllTimeOrganization',
  Roster = 'Roster',
  AllTimeRoster = 'AllTimeRoster',
  TeamTransferPortal = 'TeamTransferPortal',
  AllTimeTeamTransferPortal = 'AllTimeTeamTransferPortal',
  TeamComposite = 'TeamComposite',
  AllTimeTeamComposite = 'AllTimeTeamComposite',
  ProjectedImpact = 'ProjectedImpact',
}

export interface RenovateNilCollectiveTierRequest {
  /** @format int64 */
  collectiveKey: number;
  /** @format int64 */
  key?: number | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  name?: string;
  /** @format int32 */
  order: number;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  percent: number;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  personLimit: number;
  type: CollectiveTierTypes;
}

export interface RenovateNilCollectiveTierValuationDraftRequest {
  /**
   * @format double
   * @min 0
   * @max 9999999999.99
   */
  actualValue?: number | null;
  /** @format int64 */
  collectiveTierKey: number;
  /** @format int64 */
  key?: number | null;
  /** @format int32 */
  order: number;
  /** @format int64 */
  personSportKey: number;
  /** @format int64 */
  personSportOrganizationKey?: number | null;
  /**
   * @format double
   * @min 0
   * @max 9999999999.99
   */
  projectedValue: number;
  /** @format int64 */
  recruitmentInterestKey?: number | null;
}

export interface RenovateNilComplianceRequest {
  currentRules?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  governingRuleLabel?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  governingRuleUrl?: string | null;
  /** @format int64 */
  key?: number | null;
  monetizationAllowed?: boolean | null;
  organizationType: OrganizationType;
  /** @format int32 */
  stateKey: number;
}

export interface RenovateNotsbgRequest {
  /** @format int64 */
  key?: number | null;
  organizationType: OrganizationType;
  rankingType: RankingType;
  /** @format int32 */
  sportKey: number;
  /**
   * @format int32
   * @min 2000
   * @max 3000
   */
  year?: number | null;
}

export interface RenovateNotsrbRequest {
  /** @format int64 */
  endingRank?: number | null;
  /** @format double */
  endingRating?: number | null;
  /** @format double */
  endingValue: number;
  functionType: NilValueBoostFunctionType;
  /** @format int64 */
  key?: number | null;
  /** @format int64 */
  notsbgKey: number;
  /** @format double */
  stairStepValue?: number | null;
  /** @format int64 */
  startingRank?: number | null;
  /** @format double */
  startingRating?: number | null;
  /** @format double */
  startingValue: number;
}

export interface RenovateNpbgRequest {
  /** @format int64 */
  key?: number | null;
  /** @format int64 */
  notsbgKey: number;
  /** @format int32 */
  positionKey: number;
}

export interface RenovateNprbRequest {
  /** @format int64 */
  endingRank?: number | null;
  /** @format double */
  endingRating?: number | null;
  /** @format double */
  endingValue: number;
  functionType: NilValueBoostFunctionType;
  /** @format int64 */
  key?: number | null;
  /** @format int64 */
  npbgKey: number;
  /** @format double */
  stairStepValue?: number | null;
  /** @format int64 */
  startingRank?: number | null;
  /** @format double */
  startingRating?: number | null;
  /** @format double */
  startingValue: number;
}

export interface RenovateOseoRequest {
  /** @format int64 */
  key?: number | null;
  /** @format int32 */
  organizationKey: number;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  override: number;
  /** @format int32 */
  sportKey: number;
}

export interface RenovatePersonSocialMetricRequest {
  /** @format int64 */
  followers: number;
  /** @format int64 */
  following: number;
  /** @format int64 */
  key?: number | null;
  /** @format int64 */
  mediaCount: number;
  /** @format int64 */
  personKey: number;
  socialType: SocialType;
  verified: boolean;
}

export interface ResponseMessage {
  message?: string;
  /** @format int32 */
  statusCode: number;
}

export enum ScrapeErrorType {
  Unknown = 'Unknown',
  RateLimited = 'RateLimited',
  MissingResource = 'MissingResource',
  PrivateResource = 'PrivateResource',
  InvalidResource = 'InvalidResource',
  SuspendedResource = 'SuspendedResource',
}

export interface SimplePersonResponse {
  /** @format int32 */
  classYear: number;
  firstName?: string;
  fullName?: string;
  /** @format int64 */
  key: number;
  knownAsName?: string | null;
  lastName?: string;
  slug?: string;
}

export interface SmallPlayerResponse {
  /** @format int32 */
  classYear?: number | null;
  defaultAsset?: AssetResponse;
  firstName?: string;
  fullName?: string;
  height?: string | null;
  /** @format int64 */
  key: number;
  lastName?: string;
  primaryPosition?: AttributeResponse;
  /** @format int64 */
  recruitmentKey?: number | null;
  slug?: string;
  /** @format double */
  weight?: number | null;
}

export interface SocialRequest {
  handle?: string | null;
}

export enum SocialType {
  Unknown = 'Unknown',
  Twitter = 'Twitter',
  Instagram = 'Instagram',
  TikTok = 'TikTok',
  Facebook = 'Facebook',
  Youtube = 'Youtube',
}

export interface SportResponseRecord {
  abbreviation?: string;
  isIndustryRankable: boolean;
  isRankable: boolean;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface SportVamcResponse {
  /** @format int64 */
  count: number;
  sport?: SportResponseRecord;
}

export interface SportVamcResponsePagedData {
  list?: SportVamcResponse[];
  pagination?: Pagination;
}

export interface StateResponseRecord {
  abbreviation?: string;
  /** @format int32 */
  countryKey?: number | null;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface StateVamcResponse {
  /** @format int64 */
  count: number;
  state?: StateResponseRecord;
}

export interface StateVamcResponsePagedData {
  list?: StateVamcResponse[];
  pagination?: Pagination;
}

export interface StringListResourceResponseMessage {
  message?: string;
  resource?: string[];
  /** @format int32 */
  statusCode: number;
}

export interface StringValueVisibility {
  value?: string | null;
  visibility: Visibility;
}

export enum TermAmount {
  OneTime = 'OneTime',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Annually = 'Annually',
}

export interface Token {
  access_token?: string | null;
  /** @format double */
  expires_in?: number;
  token_type?: string | null;
  /** @format int64 */
  user_id?: number;
}

export interface UInt64StringKeyValuePair {
  /** @format int64 */
  key: number;
  value?: string;
}

export interface UpdateBaseCollectiveOrgRequest {
  /** @format double */
  minimumWage?: number | null;
  /**
   * @format int32
   * @min -10
   * @max 30
   */
  modifier: number;
  /**
   * @format double
   * @min 0
   * @max 100
   */
  recruitmentPercent: number;
}

export interface UpdateBaseCollectiveRequest {
  /** @format int32 */
  baseValue: number;
}

/**
 * Response representing a user and the JWT
 * associated
 */
export interface UserJwtResponse {
  /** The display name of the user */
  displayName?: string | null;
  email: string | null;
  /** Does the user have at least one active subscription */
  hasActiveSubscription: boolean;
  latestTransactionId?: string;
  /** @format int64 */
  personKey?: number | null;
  /**
   * The pianoId of the user
   * @format uuid
   */
  pianoId?: string | null;
  promotionEligible: boolean;
  /**
   * The time when the Token will be expired
   * @format date-time
   */
  refreshBefore: string;
  /** The refresh token used to get a new Token */
  refreshToken?: string;
  /**
   * The date/time the refresh token expires
   * @format date-time
   */
  refreshTokenExpiry: string;
  /** The list of string roles */
  roles?: string[];
  sites?: number[];
  /** What is the max type of subscription a user has */
  subscriptionMaxType?: string;
  /** List of active subscription keys */
  subscriptions?: number[];
  /** The JWT to use */
  token?: string;
  /**
   * The id of the user
   * @format int64
   */
  userId: number;
  /** The username of the user */
  username?: string;
}

export interface V1AgentsAutocompleteListParams {
  term?: string;
}

export interface V1AgentsListParams {
  /**
   * @format int32
   * @min 1
   * @max 50
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 1
   */
  page?: number;
}

export interface V1AnnouncementImageRequestCompletedImageCreatePayload {
  completedImages: File[];
}

export interface V1AnnouncementImageRequestCreatePayload {
  additionalInformation?: string;
  artistRequested?: boolean;
  /** @format date-time */
  dateOfRelease?: string;
  emailAddress: string;
  graphicType: AnnouncementImageGraphicType;
  /** @format int64 */
  personKey: number;
  schools?: number[];
  showOnProfileRequested?: boolean;
  /** @format int32 */
  sportKey: number;
  userUploadedFiles?: File[];
}

export interface V1AnnouncementImageRequestShowOnProfileUpdateParams {
  /** @format int64 */
  key: number;
  showOnProfile?: boolean;
}

export interface V1AnnouncementImageRequestStatusUpdateParams {
  /** @format int64 */
  key: number;
  newStatus?: AnnouncementImageStatus;
}

export interface V1AnnouncementImageRequestsListParams {
  /**
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int64 */
  personKey?: number;
}

export interface V1BaseCollectivesListParams {
  /**
   * @format int32
   * @min 1
   * @max 50
   */
  items?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** @format int32 */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V1BaseCollectivesOrganizationsPublishValuationsCreateParams {
  /** @format int64 */
  baseCollectiveKey: number;
  /** @default false */
  createNewVersion?: boolean;
  /** @format int32 */
  organizationKey: number;
}

export type V1CollectiveTierValuationDraftsRenovateUpdatePayload =
  RenovateNilCollectiveTierValuationDraftRequest[];

export type V1CollectiveTiersRenovateUpdatePayload =
  RenovateNilCollectiveTierRequest[];

export interface V1DealsAutocompleteCompaniesListParams {
  term?: string;
}

export interface V1DealsCompaniesListParams {
  /**
   * @format int32
   * @min 1
   * @max 50
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 1
   */
  page?: number;
}

export interface V1DealsListParams {
  /**
   * The number to return in each page
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * The page number
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The year for a deal
   * @format int32
   */
  year?: number;
}

export interface V1DealsSubmissionsDeleteParams {
  /** @format int64 */
  dealKey: number;
  fileKey?: string;
}

export interface V1DealsSubmissionsDownloadDetailParams {
  /** @format int64 */
  dealKey: number;
  fileKey?: string;
}

export interface V1DealsSubmissionsListParams {
  /** Optional status of the deal */
  status?: NILDealStatus;
}

export interface V1DealsSubmissionsUpdatePayload {
  agentName?: string;
  /** @format double */
  amount?: number;
  collectiveGroupName?: string;
  companyName?: string;
  /** @format date-time */
  date?: string;
  /** @format int64 */
  key?: number;
  /** @format int64 */
  personKey: number;
  public?: boolean;
  sourceUrl?: string;
  status?: NILDealStatus;
  statusReason?: string;
  supportingDocuments?: File[];
  term?: TermAmount;
}

export interface V1InstagramAuthenticateListParams {
  code?: string;
  /** @default true */
  isWeb?: boolean;
  state?: string;
}

export interface V1InstagramMediaDetailParams {
  playerId: string;
}

export interface V1InstagramRefreshListParams {
  /** @format int64 */
  playerId?: number;
}

export interface V1InstagramRefreshNearlyExpiredLocCreateParams {
  /** @default true */
  background?: boolean;
}

export interface V1NilAverageKpisListParams {
  /** @format date-time */
  date?: string;
}

export interface V1NilErrorsListParams {
  /** @format date-time */
  after?: string;
  /** @format date-time */
  before?: string;
  errorType?: ScrapeErrorType;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  socialTypes?: SocialType[];
}

export interface V1NilGenerateKpisCreateParams {
  /** @format date-time */
  date?: string;
}

export interface V1NilImportInflcrCreateParams {
  /**
   * @format int32
   * @default 100
   */
  errorLimit?: number;
}

export interface V1NilImportInflcrCreatePayload {
  /** @format binary */
  csvFile?: File;
}

export interface V1NilManualBoostsListParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1NilMoneyWantedAdminListParams {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  page?: number;
  /** @format int64 */
  personKey?: number;
}

export interface V1NilOrgSportExpOverridesListParams {
  /**
   * Valid range is 1 - 100
   * @format int32
   * @min 1
   * @max 100
   */
  items?: number;
  /** @format int32 */
  organizationKey?: number;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  page?: number;
  /** @format int32 */
  sportKey?: number;
}

export interface V1NilOrgTypeSportBoostGroupsListParams {
  organizationType?: OrganizationType;
  rankingType?: RankingType;
  /** @format int32 */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V1NilOverallKpisListParams {
  /** @format date-time */
  date?: string;
}

export interface V1NilProcessRankingCreateParams {
  /** @format date-time */
  date?: string;
}

export interface V1NilProcessRankingLocCreateParams {
  /** @format date-time */
  date?: string;
}

export interface V1NilPublishRankingCreateParams {
  /** @format date-time */
  date?: string;
}

export interface V1NilPublishRankingLocCreateParams {
  /** @format date-time */
  date?: string;
}

export interface V1NilValuationAnomaliesListParams {
  /** @format date-time */
  date?: string;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1NilValuationDraftsListParams {
  /**
   * Valid range is 1 - 100
   * @format int32
   * @min 1
   * @max 100
   */
  items?: number;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  page?: number;
}

export interface V1OrganizationsRosterValuationBreakdownsDetailParams {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  sportKey: number;
  /**
   * @format int32
   * @min 1869
   * @max 3000
   */
  year: number;
}

export interface V1PeopleNilDashboardValuationBreakdownDetailParams {
  /** @format int32 */
  organizationKey?: number;
  /** @format int64 */
  personKey: number;
}

export type V1SocialMetricsRenovateUpdatePayload =
  RenovatePersonSocialMetricRequest[];

export interface ValuationResponse {
  /** @format int64 */
  groupRank?: number | null;
  /** @format int64 */
  key: number;
  nilStatus: NilStatuses;
  /** @format int64 */
  rank?: number | null;
  /** @format int64 */
  totalValue?: number | null;
}

export interface VerifiedAthleteMetricsResponse {
  organizationTypes?: OrganizationTypeVamcResponsePagedData;
  organizations?: OrganizationVamcResponsePagedData;
  positions?: PositionVamcResponsePagedData;
  sports?: SportVamcResponsePagedData;
  stars?: RankingStarsVamcResponsePagedData;
  states?: StateVamcResponsePagedData;
}

export enum Visibility {
  Private = 'Private',
  Friends = 'Friends',
  Athletes = 'Athletes',
  Public = 'Public',
  Unknown = 'Unknown',
}

export type QueryParamsType = Record<string | number, any>;

export type RequestParams = Omit<
  AxiosRequestConfig,
  'data' | 'method' | 'params' | 'url' | 'responseType'
>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/**
 * No description
 *
 * @tags NilDashboard
 * @name DeleteRdb
 * @summary  (Roles: registered user)
 * @request DELETE:/rdb/v1
 * @secure
 */
export const deleteRdb = (
  client: AxiosInstance,
  dealKey: number,
  params: RequestParams = {},
) =>
  client
    .request<DeleteResponse>({
      url: `/rdb/v1`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Agent
 * @name V1AgentsAutocompleteList
 * @summary Autocomplete list of agents based off of token
 * @request GET:/rdb/v1/agents/autocomplete
 * @secure
 */
export const v1AgentsAutocompleteList = (
  client: AxiosInstance,
  query: V1AgentsAutocompleteListParams,
  params: RequestParams = {},
) =>
  client
    .request<UInt64StringKeyValuePair[]>({
      url: `/rdb/v1/agents/autocomplete`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Agent
 * @name V1AgentsDelete
 * @summary Deletes Agent for a key
 * @request DELETE:/rdb/v1/agents/{agentKey}
 * @secure
 */
export const v1AgentsDelete = (
  client: AxiosInstance,
  agentKey: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/agents/${agentKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Agent
 * @name V1AgentsDetail
 * @summary Gets agent from agent key
 * @request GET:/rdb/v1/agents/{agentKey}
 * @secure
 */
export const v1AgentsDetail = (
  client: AxiosInstance,
  agentKey: number,
  params: RequestParams = {},
) =>
  client
    .request<AgentResponse>({
      url: `/rdb/v1/agents/${agentKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Agent
 * @name V1AgentsList
 * @summary Gets all agents
 * @request GET:/rdb/v1/agents
 * @secure
 */
export const v1AgentsList = (
  client: AxiosInstance,
  query: V1AgentsListParams,
  params: RequestParams = {},
) =>
  client
    .request<AgentResponsePagedData>({
      url: `/rdb/v1/agents`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Agent
 * @name V1AgentsRenovateUpdate
 * @summary Updates or Adds an agent
 * @request PUT:/rdb/v1/agents/renovate
 * @secure
 */
export const v1AgentsRenovateUpdate = (
  client: AxiosInstance,
  data: AgentRequest,
  params: RequestParams = {},
) =>
  client
    .request<AgentResponse[]>({
      url: `/rdb/v1/agents/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1AnnouncementImageRequestCompletedImageCreate
 * @summary  (Roles: admin, rdb1, rdb2, rdb3, rdb4, rdb5, developer, rdb_graphic_edits)
 * @request POST:/rdb/v1/announcement-image-request/{key}/completed-image
 * @secure
 */
export const v1AnnouncementImageRequestCompletedImageCreate = (
  client: AxiosInstance,
  key: number,
  data: V1AnnouncementImageRequestCompletedImageCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<AnnouncementImageRequestResponse>({
      url: `/rdb/v1/announcement-image-request/${key}/completed-image`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1AnnouncementImageRequestCreate
 * @summary  (Roles: registered user)
 * @request POST:/rdb/v1/announcement-image-request
 * @secure
 */
export const v1AnnouncementImageRequestCreate = (
  client: AxiosInstance,
  data: V1AnnouncementImageRequestCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<AnnouncementImageRequestResponse>({
      url: `/rdb/v1/announcement-image-request`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1AnnouncementImageRequestDelete
 * @summary  (Roles: admin, rdb1, rdb2, rdb3, rdb4, rdb5, developer, rdb_graphic_edits)
 * @request DELETE:/rdb/v1/announcement-image-request/{key}
 * @secure
 */
export const v1AnnouncementImageRequestDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<DeleteResponse>({
      url: `/rdb/v1/announcement-image-request/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1AnnouncementImageRequestsDetail
 * @summary  (Roles: registered user)
 * @request GET:/rdb/v1/announcement-image-requests/{key}
 * @secure
 */
export const v1AnnouncementImageRequestsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<AnnouncementImageRequestResponse>({
      url: `/rdb/v1/announcement-image-requests/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1AnnouncementImageRequestShowOnProfileUpdate
 * @summary  (Roles: registered user)
 * @request PUT:/rdb/v1/announcement-image-request/{key}/showOnProfile
 * @secure
 */
export const v1AnnouncementImageRequestShowOnProfileUpdate = (
  client: AxiosInstance,
  { key, ...query }: V1AnnouncementImageRequestShowOnProfileUpdateParams,
  params: RequestParams = {},
) =>
  client
    .request<AnnouncementImageRequestResponse>({
      url: `/rdb/v1/announcement-image-request/${key}/showOnProfile`,
      method: 'PUT',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1AnnouncementImageRequestsList
 * @summary  (Roles: registered user)
 * @request GET:/rdb/v1/announcement-image-requests
 * @secure
 */
export const v1AnnouncementImageRequestsList = (
  client: AxiosInstance,
  query: V1AnnouncementImageRequestsListParams,
  params: RequestParams = {},
) =>
  client
    .request<AnnouncementImageRequestResponsePagedData>({
      url: `/rdb/v1/announcement-image-requests`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1AnnouncementImageRequestStatusUpdate
 * @summary  (Roles: admin, rdb1, rdb2, rdb3, rdb4, rdb5, developer, rdb_graphic_edits)
 * @request PUT:/rdb/v1/announcement-image-request/{key}/status
 * @secure
 */
export const v1AnnouncementImageRequestStatusUpdate = (
  client: AxiosInstance,
  { key, ...query }: V1AnnouncementImageRequestStatusUpdateParams,
  params: RequestParams = {},
) =>
  client
    .request<AnnouncementImageRequestResponse>({
      url: `/rdb/v1/announcement-image-request/${key}/status`,
      method: 'PUT',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1AthleteActivationCreate
 * @summary  (Roles: registered user)
 * @request POST:/rdb/v1/athlete-activation
 * @secure
 */
export const v1AthleteActivationCreate = (
  client: AxiosInstance,
  data: AthleteActivationRequestRequest,
  params: RequestParams = {},
) =>
  client
    .request<AthleteActivationRequestResponse>({
      url: `/rdb/v1/athlete-activation`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags BaseCollective
 * @name V1BaseCollectivesCreate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request POST:/rdb/v1/base-collectives
 * @secure
 */
export const v1BaseCollectivesCreate = (
  client: AxiosInstance,
  data: CreateBaseCollectiveRequest,
  params: RequestParams = {},
) =>
  client
    .request<BaseCollectiveWithOrgsResponse>({
      url: `/rdb/v1/base-collectives`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags BaseCollective
 * @name V1BaseCollectivesDelete
 * @summary  (Roles: developer)
 * @request DELETE:/rdb/v1/base-collectives/{key}
 * @secure
 */
export const v1BaseCollectivesDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/base-collectives/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags BaseCollective
 * @name V1BaseCollectivesDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request GET:/rdb/v1/base-collectives/{key}
 * @secure
 */
export const v1BaseCollectivesDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<BaseCollectiveWithOrgsResponse>({
      url: `/rdb/v1/base-collectives/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags BaseCollective
 * @name V1BaseCollectivesEnsureRecruitmentValuationsCreate
 * @summary  (Roles: developer)
 * @request POST:/rdb/v1/base-collectives/{baseCollectiveKey}/ensure-recruitment-valuations
 * @secure
 */
export const v1BaseCollectivesEnsureRecruitmentValuationsCreate = (
  client: AxiosInstance,
  baseCollectiveKey: number,
  params: RequestParams = {},
) =>
  client
    .request<any>({
      url: `/rdb/v1/base-collectives/${baseCollectiveKey}/ensure-recruitment-valuations`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags BaseCollective
 * @name V1BaseCollectivesList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request GET:/rdb/v1/base-collectives
 * @secure
 */
export const v1BaseCollectivesList = (
  client: AxiosInstance,
  query: V1BaseCollectivesListParams,
  params: RequestParams = {},
) =>
  client
    .request<BaseCollectiveResponsePagedData>({
      url: `/rdb/v1/base-collectives`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags BaseCollective
 * @name V1BaseCollectivesOrganizationsCreate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request POST:/rdb/v1/base-collectives/{baseCollectiveKey}/organizations
 * @secure
 */
export const v1BaseCollectivesOrganizationsCreate = (
  client: AxiosInstance,
  baseCollectiveKey: number,
  data: AddBaseCollectiveOrgRequest,
  params: RequestParams = {},
) =>
  client
    .request<BaseCollectiveOrgWithTiersResponse>({
      url: `/rdb/v1/base-collectives/${baseCollectiveKey}/organizations`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags BaseCollective
 * @name V1BaseCollectivesOrganizationsDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request DELETE:/rdb/v1/base-collectives/{baseCollectiveKey}/organizations/{organizationKey}
 * @secure
 */
export const v1BaseCollectivesOrganizationsDelete = (
  client: AxiosInstance,
  baseCollectiveKey: number,
  organizationKey: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/base-collectives/${baseCollectiveKey}/organizations/${organizationKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags BaseCollective
 * @name V1BaseCollectivesOrganizationsDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request GET:/rdb/v1/base-collectives/{baseCollectiveKey}/organizations/{organizationKey}
 * @secure
 */
export const v1BaseCollectivesOrganizationsDetail = (
  client: AxiosInstance,
  baseCollectiveKey: number,
  organizationKey: number,
  params: RequestParams = {},
) =>
  client
    .request<BaseCollectiveOrgWithTiersResponse>({
      url: `/rdb/v1/base-collectives/${baseCollectiveKey}/organizations/${organizationKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags BaseCollective
 * @name V1BaseCollectivesOrganizationsPublishValuationsCreate
 * @summary  (Roles: developer, rdb5)
 * @request POST:/rdb/v1/base-collectives/{baseCollectiveKey}/organizations/{organizationKey}/publish-valuations
 * @secure
 */
export const v1BaseCollectivesOrganizationsPublishValuationsCreate = (
  client: AxiosInstance,
  {
    baseCollectiveKey,
    organizationKey,
    ...query
  }: V1BaseCollectivesOrganizationsPublishValuationsCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<BaseCollectiveOrgWithTiersResponse>({
      url: `/rdb/v1/base-collectives/${baseCollectiveKey}/organizations/${organizationKey}/publish-valuations`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags BaseCollective
 * @name V1BaseCollectivesOrganizationsUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request PUT:/rdb/v1/base-collectives/{baseCollectiveKey}/organizations/{organizationKey}
 * @secure
 */
export const v1BaseCollectivesOrganizationsUpdate = (
  client: AxiosInstance,
  baseCollectiveKey: number,
  organizationKey: number,
  data: UpdateBaseCollectiveOrgRequest,
  params: RequestParams = {},
) =>
  client
    .request<BaseCollectiveOrgWithTiersResponse>({
      url: `/rdb/v1/base-collectives/${baseCollectiveKey}/organizations/${organizationKey}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags BaseCollective
 * @name V1BaseCollectivesProcessStaleLocCreate
 * @request POST:/rdb/v1/base-collectives/process-stale-loc
 */
export const v1BaseCollectivesProcessStaleLocCreate = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<any>({
      url: `/rdb/v1/base-collectives/process-stale-loc`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags BaseCollective
 * @name V1BaseCollectivesUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request PUT:/rdb/v1/base-collectives/{key}
 * @secure
 */
export const v1BaseCollectivesUpdate = (
  client: AxiosInstance,
  key: number,
  data: UpdateBaseCollectiveRequest,
  params: RequestParams = {},
) =>
  client
    .request<BaseCollectiveWithOrgsResponse>({
      url: `/rdb/v1/base-collectives/${key}`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags CollectiveTier
 * @name V1CollectiveTiersDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request DELETE:/rdb/v1/collective-tiers/{key}
 * @secure
 */
export const v1CollectiveTiersDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/collective-tiers/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags CollectiveTier
 * @name V1CollectiveTiersDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request GET:/rdb/v1/collective-tiers/{key}
 * @secure
 */
export const v1CollectiveTiersDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<CollectiveTierResponse>({
      url: `/rdb/v1/collective-tiers/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags CollectiveTier
 * @name V1CollectiveTiersRenovateUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request PUT:/rdb/v1/collective-tiers/renovate
 * @secure
 */
export const v1CollectiveTiersRenovateUpdate = (
  client: AxiosInstance,
  data: V1CollectiveTiersRenovateUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<CollectiveTierResponseRenovateResponsePagedData>({
      url: `/rdb/v1/collective-tiers/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags CollectiveTierValuationDraft
 * @name V1CollectiveTierValuationDraftsDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request DELETE:/rdb/v1/collective-tier-valuation-drafts/{key}
 * @secure
 */
export const v1CollectiveTierValuationDraftsDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/collective-tier-valuation-drafts/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags CollectiveTierValuationDraft
 * @name V1CollectiveTierValuationDraftsRenovateUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request PUT:/rdb/v1/collective-tier-valuation-drafts/renovate
 * @secure
 */
export const v1CollectiveTierValuationDraftsRenovateUpdate = (
  client: AxiosInstance,
  data: V1CollectiveTierValuationDraftsRenovateUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<CollectiveTierValuationDraftResponseRenovateResponsePagedData>({
      url: `/rdb/v1/collective-tier-valuation-drafts/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V1CompaniesDealsDelete
 * @summary Deletes NIL Partner Company for a key
 * @request DELETE:/rdb/v1/companies/{companyKey}/deals
 * @secure
 */
export const v1CompaniesDealsDelete = (
  client: AxiosInstance,
  companyKey: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/companies/${companyKey}/deals`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V1CompaniesDealsDetail
 * @summary  (Roles: developer, rdb2, rdb3, rdb4, rdb5)
 * @request GET:/rdb/v1/companies/{companyKey}/deals
 * @secure
 */
export const v1CompaniesDealsDetail = (
  client: AxiosInstance,
  companyKey: number,
  params: RequestParams = {},
) =>
  client
    .request<NilCompanyAdminResponse>({
      url: `/rdb/v1/companies/${companyKey}/deals`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V1DealsAutocompleteCompaniesList
 * @summary Autocomplete list of companies based off of token
 * @request GET:/rdb/v1/deals/autocomplete-companies
 * @secure
 */
export const v1DealsAutocompleteCompaniesList = (
  client: AxiosInstance,
  query: V1DealsAutocompleteCompaniesListParams,
  params: RequestParams = {},
) =>
  client
    .request<UInt64StringKeyValuePair[]>({
      url: `/rdb/v1/deals/autocomplete-companies`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V1DealsCompaniesDelete
 * @summary Deletes NIL Partner Company for a key
 * @request DELETE:/rdb/v1/deals/companies/{companyKey}
 * @secure
 */
export const v1DealsCompaniesDelete = (
  client: AxiosInstance,
  companyKey: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/deals/companies/${companyKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V1DealsCompaniesDetail
 * @summary  (Roles: developer, rdb2, rdb3, rdb4, rdb5)
 * @request GET:/rdb/v1/deals/companies/{companyKey}
 * @secure
 */
export const v1DealsCompaniesDetail = (
  client: AxiosInstance,
  companyKey: number,
  params: RequestParams = {},
) =>
  client
    .request<NilCompanyAdminResponse>({
      url: `/rdb/v1/deals/companies/${companyKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V1DealsCompaniesList
 * @summary Gets all Companies that have NIL deals
 * @request GET:/rdb/v1/deals/companies
 * @secure
 */
export const v1DealsCompaniesList = (
  client: AxiosInstance,
  query: V1DealsCompaniesListParams,
  params: RequestParams = {},
) =>
  client
    .request<NilCompanyAdminResponsePagedData>({
      url: `/rdb/v1/deals/companies`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V1DealsDelete
 * @summary Deletes NIL deal for a key
 * @request DELETE:/rdb/v1/deals/{dealKey}
 * @secure
 */
export const v1DealsDelete = (
  client: AxiosInstance,
  dealKey: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/deals/${dealKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V1DealsDetail
 * @summary Gets a Deal by key
 * @request GET:/rdb/v1/deals/{dealKey}
 * @secure
 */
export const v1DealsDetail = (
  client: AxiosInstance,
  dealKey: number,
  params: RequestParams = {},
) =>
  client
    .request<AdminPlayerDealResponse>({
      url: `/rdb/v1/deals/${dealKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V1DealsList
 * @summary Gets all NIL Deals
 * @request GET:/rdb/v1/deals
 * @secure
 */
export const v1DealsList = (
  client: AxiosInstance,
  query: V1DealsListParams,
  params: RequestParams = {},
) =>
  client
    .request<AdminPlayerDealResponsePagedData>({
      url: `/rdb/v1/deals`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V1DealsPlayersDetail
 * @summary Gets all NIL Deals for a player
 * @request GET:/rdb/v1/deals/players/{personKey}
 * @secure
 */
export const v1DealsPlayersDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<AdminPlayerDealResponsePagedData>({
      url: `/rdb/v1/deals/players/${personKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V1DealsRenovateCompaniesUpdate
 * @summary Updates or Adds a company to the companies with NIL deals
 * @request PUT:/rdb/v1/deals/renovate-companies
 * @secure
 */
export const v1DealsRenovateCompaniesUpdate = (
  client: AxiosInstance,
  data: NilCompanyPartnerRequest,
  params: RequestParams = {},
) =>
  client
    .request<NilCompanyAdminResponse>({
      url: `/rdb/v1/deals/renovate-companies`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V1DealsRenovatePlayerUpdate
 * @summary Updates or Adds an NIL deal for a player
 * @request PUT:/rdb/v1/deals/renovate-player
 * @secure
 */
export const v1DealsRenovatePlayerUpdate = (
  client: AxiosInstance,
  data: PlayerNilDealRequest,
  params: RequestParams = {},
) =>
  client
    .request<AdminPlayerDealResponse>({
      url: `/rdb/v1/deals/renovate-player`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1DealsSubmissionsDelete
 * @summary Deletes NIL Deal Submission file
 * @request DELETE:/rdb/v1/deals/submissions/{dealKey}
 * @deprecated
 * @secure
 */
export const v1DealsSubmissionsDelete = (
  client: AxiosInstance,
  { dealKey, ...query }: V1DealsSubmissionsDeleteParams,
  params: RequestParams = {},
) =>
  client
    .request<NilDealSubmissionResponsePagedData>({
      url: `/rdb/v1/deals/submissions/${dealKey}`,
      method: 'DELETE',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1DealsSubmissionsDetail
 * @summary Gets NIL Deals submissions for a key
 * @request GET:/rdb/v1/deals/submissions/{dealKey}
 * @secure
 */
export const v1DealsSubmissionsDetail = (
  client: AxiosInstance,
  dealKey: number,
  params: RequestParams = {},
) =>
  client
    .request<NilDealSubmissionResponse>({
      url: `/rdb/v1/deals/submissions/${dealKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1DealsSubmissionsDetail2
 * @summary Gets all NIL Deals submissions for a player
 * @request GET:/rdb/v1/deals/{personKey}/submissions
 * @originalName v1DealsSubmissionsDetail
 * @duplicate
 * @secure
 */
export const v1DealsSubmissionsDetail2 = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<NilDealSubmissionResponsePagedData>({
      url: `/rdb/v1/deals/${personKey}/submissions`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1DealsSubmissionsDownloadDetail
 * @summary Downloads an NIL Deal File
 * @request GET:/rdb/v1/deals/submissions/{dealKey}/download
 * @secure
 */
export const v1DealsSubmissionsDownloadDetail = (
  client: AxiosInstance,
  { dealKey, ...query }: V1DealsSubmissionsDownloadDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<File>({
      url: `/rdb/v1/deals/submissions/${dealKey}/download`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1DealsSubmissionsList
 * @summary Admin- Gets all NIL Deals submissions for a status
 * @request GET:/rdb/v1/deals/submissions
 * @secure
 */
export const v1DealsSubmissionsList = (
  client: AxiosInstance,
  query: V1DealsSubmissionsListParams,
  params: RequestParams = {},
) =>
  client
    .request<NilDealSubmissionResponsePagedData>({
      url: `/rdb/v1/deals/submissions`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1DealsSubmissionsUpdate
 * @summary Adds or updates an NIL Deals submission
 * @request PUT:/rdb/v1/deals/submissions
 * @secure
 */
export const v1DealsSubmissionsUpdate = (
  client: AxiosInstance,
  data: V1DealsSubmissionsUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<NilDealSubmissionResponsePagedData>({
      url: `/rdb/v1/deals/submissions`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TokenAuth
 * @name V1InstagramAuthenticateList
 * @summary Authenticate Instagram Handle and User
 * @request GET:/rdb/v1/instagram/authenticate
 */
export const v1InstagramAuthenticateList = (
  client: AxiosInstance,
  query: V1InstagramAuthenticateListParams,
  params: RequestParams = {},
) =>
  client
    .request<UserJwtResponse>({
      url: `/rdb/v1/instagram/authenticate`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TokenAuth
 * @name V1InstagramAuthorizeDetail
 * @summary Get a auth url for instagram
 * @request GET:/rdb/v1/instagram/{playerId}/authorize
 * @secure
 */
export const v1InstagramAuthorizeDetail = (
  client: AxiosInstance,
  playerId: number,
  params: RequestParams = {},
) =>
  client
    .request<string>({
      url: `/rdb/v1/instagram/${playerId}/authorize`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TokenAuth
 * @name V1InstagramDeleteCallbackCreate
 * @summary Callback for facebook only to remove data with signed request
 * @request POST:/rdb/v1/instagram/delete-callback
 */
export const v1InstagramDeleteCallbackCreate = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/instagram/delete-callback`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TokenAuth
 * @name V1InstagramGraphUpdate
 * @summary Handshake
 * @request PUT:/rdb/v1/instagram-graph
 * @secure
 */
export const v1InstagramGraphUpdate = (
  client: AxiosInstance,
  data: ClaimRequest,
  params: RequestParams = {},
) =>
  client
    .request<Token>({
      url: `/rdb/v1/instagram-graph`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TokenAuth
 * @name V1InstagramMediaDetail
 * @summary Get User's Instagram Media
 * @request GET:/rdb/v1/instagram/{playerId}/media
 * @secure
 */
export const v1InstagramMediaDetail = (
  client: AxiosInstance,
  { playerId, ...query }: V1InstagramMediaDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<MediaResponse>({
      url: `/rdb/v1/instagram/${playerId}/media`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TokenAuth
 * @name V1InstagramRefreshList
 * @summary Refresh Instagram Token
 * @request GET:/rdb/v1/instagram/refresh
 * @secure
 */
export const v1InstagramRefreshList = (
  client: AxiosInstance,
  query: V1InstagramRefreshListParams,
  params: RequestParams = {},
) =>
  client
    .request<Token>({
      url: `/rdb/v1/instagram/refresh`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TokenAuth
 * @name V1InstagramRefreshNearlyExpiredLocCreate
 * @request POST:/rdb/v1/instagram/refresh-nearly-expired-loc
 */
export const v1InstagramRefreshNearlyExpiredLocCreate = (
  client: AxiosInstance,
  query: V1InstagramRefreshNearlyExpiredLocCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/instagram/refresh-nearly-expired-loc`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TokenAuth
 * @name V1InstagramRemoveHandshakeCreate
 * @summary Gets the author information for a author slug
 * @request POST:/rdb/v1/instagram/{playerId}/remove-handshake
 * @secure
 */
export const v1InstagramRemoveHandshakeCreate = (
  client: AxiosInstance,
  playerId: number,
  params: RequestParams = {},
) =>
  client
    .request<Token>({
      url: `/rdb/v1/instagram/${playerId}/remove-handshake`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilReport
 * @name V1NilAverageKpisList
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/nil/average-kpis
 * @secure
 */
export const v1NilAverageKpisList = (
  client: AxiosInstance,
  query: V1NilAverageKpisListParams,
  params: RequestParams = {},
) =>
  client
    .request<NilAveragesReportResponse>({
      url: `/rdb/v1/nil/average-kpis`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilCompliance
 * @name V1NilCompliancesDelete
 * @summary  (Roles: developer)
 * @request DELETE:/rdb/v1/nil-compliances/{key}
 * @secure
 */
export const v1NilCompliancesDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v1/nil-compliances/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilCompliance
 * @name V1NilCompliancesUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2, rdb1)
 * @request PUT:/rdb/v1/nil-compliances
 * @secure
 */
export const v1NilCompliancesUpdate = (
  client: AxiosInstance,
  data: RenovateNilComplianceRequest,
  params: RequestParams = {},
) =>
  client
    .request<NilComplianceResponse>({
      url: `/rdb/v1/nil-compliances`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Nil
 * @name V1NilErrorsList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2)
 * @request GET:/rdb/v1/nil/errors
 * @secure
 */
export const v1NilErrorsList = (
  client: AxiosInstance,
  query: V1NilErrorsListParams,
  params: RequestParams = {},
) =>
  client
    .request<NilErrorResponsePagedData>({
      url: `/rdb/v1/nil/errors`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilReport
 * @name V1NilGenerateKpisCreate
 * @summary  (Roles: developer, rdb5)
 * @request POST:/rdb/v1/nil/generate-kpis
 * @secure
 */
export const v1NilGenerateKpisCreate = (
  client: AxiosInstance,
  query: V1NilGenerateKpisCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<boolean>({
      url: `/rdb/v1/nil/generate-kpis`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilImport
 * @name V1NilImportInflcrCreate
 * @summary  (Roles: developer, nil-importer)
 * @request POST:/rdb/v1/nil/import-inflcr
 * @secure
 */
export const v1NilImportInflcrCreate = (
  client: AxiosInstance,
  query: V1NilImportInflcrCreateParams,
  data: V1NilImportInflcrCreatePayload,
  params: RequestParams = {},
) =>
  client
    .request<ImportResponse>({
      url: `/rdb/v1/nil/import-inflcr`,
      method: 'POST',
      params: query,
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilReport
 * @name V1NilManualBoostsList
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/nil/manual-boosts
 * @secure
 */
export const v1NilManualBoostsList = (
  client: AxiosInstance,
  query: V1NilManualBoostsListParams,
  params: RequestParams = {},
) =>
  client
    .request<NilValuationBoostResponsePagedData>({
      url: `/rdb/v1/nil/manual-boosts`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1NilMoneyWantedAdminList
 * @summary  (Roles: developer, nil_valuation_admin)
 * @request GET:/rdb/v1/nil/money-wanted-admin
 * @secure
 */
export const v1NilMoneyWantedAdminList = (
  client: AxiosInstance,
  query: V1NilMoneyWantedAdminListParams,
  params: RequestParams = {},
) =>
  client
    .request<MoneyWantedResponsePagedData>({
      url: `/rdb/v1/nil/money-wanted-admin`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1NilMoneyWantedCreate
 * @summary  (Roles: registered user)
 * @request POST:/rdb/v1/nil/money-wanted
 * @secure
 */
export const v1NilMoneyWantedCreate = (
  client: AxiosInstance,
  data: MoneyWantedRequest,
  params: RequestParams = {},
) =>
  client
    .request<MoneyWantedResponse>({
      url: `/rdb/v1/nil/money-wanted`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrgSportExpOverride
 * @name V1NilOrgSportExpOverridesDelete
 * @summary  (Roles: developer, rdb5)
 * @request DELETE:/rdb/v1/nil/org-sport-exp-overrides/{key}
 * @secure
 */
export const v1NilOrgSportExpOverridesDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/nil/org-sport-exp-overrides/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrgSportExpOverride
 * @name V1NilOrgSportExpOverridesList
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/nil/org-sport-exp-overrides
 * @secure
 */
export const v1NilOrgSportExpOverridesList = (
  client: AxiosInstance,
  query: V1NilOrgSportExpOverridesListParams,
  params: RequestParams = {},
) =>
  client
    .request<OrgSportExposureOverrideResponseOseoFilterResponseRelatedPagedData>(
      {
        url: `/rdb/v1/nil/org-sport-exp-overrides`,
        method: 'GET',
        params: query,
        ...params,
      },
    )
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrgSportExpOverride
 * @name V1NilOrgSportExpOverridesUpdate
 * @summary  (Roles: developer, rdb5)
 * @request PUT:/rdb/v1/nil/org-sport-exp-overrides
 * @secure
 */
export const v1NilOrgSportExpOverridesUpdate = (
  client: AxiosInstance,
  data: RenovateOseoRequest,
  params: RequestParams = {},
) =>
  client
    .request<OrgSportExposureOverrideResponse>({
      url: `/rdb/v1/nil/org-sport-exp-overrides`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilConfig
 * @name V1NilOrgTypeSportBoostGroupsDetail
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/nil/org-type-sport-boost-groups/{key}
 * @secure
 */
export const v1NilOrgTypeSportBoostGroupsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<NotsbgResponsePagedData>({
      url: `/rdb/v1/nil/org-type-sport-boost-groups/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilConfig
 * @name V1NilOrgTypeSportBoostGroupsList
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/nil/org-type-sport-boost-groups
 * @secure
 */
export const v1NilOrgTypeSportBoostGroupsList = (
  client: AxiosInstance,
  query: V1NilOrgTypeSportBoostGroupsListParams,
  params: RequestParams = {},
) =>
  client
    .request<NotsbgResponsePagedData>({
      url: `/rdb/v1/nil/org-type-sport-boost-groups`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilConfig
 * @name V1NilOrgTypeSportBoostGroupsPositionBoostGroupsDetail
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/nil/org-type-sport-boost-groups/{notsbgKey}/position-boost-groups
 * @secure
 */
export const v1NilOrgTypeSportBoostGroupsPositionBoostGroupsDetail = (
  client: AxiosInstance,
  notsbgKey: number,
  params: RequestParams = {},
) =>
  client
    .request<NpbgResponseNotsbgResponseRelatedPagedData>({
      url: `/rdb/v1/nil/org-type-sport-boost-groups/${notsbgKey}/position-boost-groups`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilConfig
 * @name V1NilOrgTypeSportBoostGroupsRankBoostsDetail
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/nil/org-type-sport-boost-groups/{notsbgKey}/rank-boosts
 * @secure
 */
export const v1NilOrgTypeSportBoostGroupsRankBoostsDetail = (
  client: AxiosInstance,
  notsbgKey: number,
  params: RequestParams = {},
) =>
  client
    .request<NotsrbResponseNotsbgResponseRelatedPagedData>({
      url: `/rdb/v1/nil/org-type-sport-boost-groups/${notsbgKey}/rank-boosts`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilConfig
 * @name V1NilOrgTypeSportBoostGroupsRenovateUpdate
 * @summary  (Roles: developer, rdb5)
 * @request PUT:/rdb/v1/nil/org-type-sport-boost-groups/renovate
 * @secure
 */
export const v1NilOrgTypeSportBoostGroupsRenovateUpdate = (
  client: AxiosInstance,
  data: RenovateNotsbgRequest,
  params: RequestParams = {},
) =>
  client
    .request<NotsbgResponse>({
      url: `/rdb/v1/nil/org-type-sport-boost-groups/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilConfig
 * @name V1NilOrgTypeSportRankBoostsDetail
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/nil/org-type-sport-rank-boosts/{key}
 * @secure
 */
export const v1NilOrgTypeSportRankBoostsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<NotsrbResponse>({
      url: `/rdb/v1/nil/org-type-sport-rank-boosts/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilConfig
 * @name V1NilOrgTypeSportRankBoostsRenovateUpdate
 * @summary  (Roles: developer, rdb5)
 * @request PUT:/rdb/v1/nil/org-type-sport-rank-boosts/renovate
 * @secure
 */
export const v1NilOrgTypeSportRankBoostsRenovateUpdate = (
  client: AxiosInstance,
  data: RenovateNotsrbRequest,
  params: RequestParams = {},
) =>
  client
    .request<NotsrbResponse>({
      url: `/rdb/v1/nil/org-type-sport-rank-boosts/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilReport
 * @name V1NilOverallKpisList
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/nil/overall-kpis
 * @secure
 */
export const v1NilOverallKpisList = (
  client: AxiosInstance,
  query: V1NilOverallKpisListParams,
  params: RequestParams = {},
) =>
  client
    .request<NilAveragesReportResponse>({
      url: `/rdb/v1/nil/overall-kpis`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Nil
 * @name V1NilPeopleProcessRankingCreate
 * @summary  (Roles: developer, rdb5)
 * @request POST:/rdb/v1/nil/people/{personKey}/process-ranking
 * @secure
 */
export const v1NilPeopleProcessRankingCreate = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonNilValuationDraftResponse>({
      url: `/rdb/v1/nil/people/${personKey}/process-ranking`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilConfig
 * @name V1NilPositionBoostGroupsDetail
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/nil/position-boost-groups/{key}
 * @secure
 */
export const v1NilPositionBoostGroupsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<NpbgResponse>({
      url: `/rdb/v1/nil/position-boost-groups/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilConfig
 * @name V1NilPositionBoostGroupsPositionRankBoostsDetail
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/nil/position-boost-groups/{npbgKey}/position-rank-boosts
 * @secure
 */
export const v1NilPositionBoostGroupsPositionRankBoostsDetail = (
  client: AxiosInstance,
  npbgKey: number,
  params: RequestParams = {},
) =>
  client
    .request<NprbResponseNpbgResponseRelatedPagedData>({
      url: `/rdb/v1/nil/position-boost-groups/${npbgKey}/position-rank-boosts`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilConfig
 * @name V1NilPositionBoostGroupsRenovateUpdate
 * @summary  (Roles: developer, rdb5)
 * @request PUT:/rdb/v1/nil/position-boost-groups/renovate
 * @secure
 */
export const v1NilPositionBoostGroupsRenovateUpdate = (
  client: AxiosInstance,
  data: RenovateNpbgRequest,
  params: RequestParams = {},
) =>
  client
    .request<NpbgResponse>({
      url: `/rdb/v1/nil/position-boost-groups/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilConfig
 * @name V1NilPositionRankBoostsDetail
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/nil/position-rank-boosts/{key}
 * @secure
 */
export const v1NilPositionRankBoostsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<NprbResponse>({
      url: `/rdb/v1/nil/position-rank-boosts/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilConfig
 * @name V1NilPositionRankBoostsRenovateUpdate
 * @summary  (Roles: developer, rdb5)
 * @request PUT:/rdb/v1/nil/position-rank-boosts/renovate
 * @secure
 */
export const v1NilPositionRankBoostsRenovateUpdate = (
  client: AxiosInstance,
  data: RenovateNprbRequest,
  params: RequestParams = {},
) =>
  client
    .request<NprbResponse>({
      url: `/rdb/v1/nil/position-rank-boosts/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Nil
 * @name V1NilProcessRankingCreate
 * @summary  (Roles: developer, rdb5)
 * @request POST:/rdb/v1/nil/process-ranking
 * @secure
 */
export const v1NilProcessRankingCreate = (
  client: AxiosInstance,
  query: V1NilProcessRankingCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<ProcessRankingResponse>({
      url: `/rdb/v1/nil/process-ranking`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Nil
 * @name V1NilProcessRankingLocCreate
 * @request POST:/rdb/v1/nil/process-ranking-loc
 */
export const v1NilProcessRankingLocCreate = (
  client: AxiosInstance,
  query: V1NilProcessRankingLocCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<ProcessRankingResponse>({
      url: `/rdb/v1/nil/process-ranking-loc`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Nil
 * @name V1NilProcessRankingStatusList
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/nil/process-ranking-status
 * @secure
 */
export const v1NilProcessRankingStatusList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<PersonNilValuationDraftResponsePagedData>({
      url: `/rdb/v1/nil/process-ranking-status`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Nil
 * @name V1NilPublishRankingCreate
 * @summary  (Roles: developer, rdb5)
 * @request POST:/rdb/v1/nil/publish-ranking
 * @secure
 */
export const v1NilPublishRankingCreate = (
  client: AxiosInstance,
  query: V1NilPublishRankingCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<PublishRankingResponse>({
      url: `/rdb/v1/nil/publish-ranking`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Nil
 * @name V1NilPublishRankingLocCreate
 * @request POST:/rdb/v1/nil/publish-ranking-loc
 */
export const v1NilPublishRankingLocCreate = (
  client: AxiosInstance,
  query: V1NilPublishRankingLocCreateParams,
  params: RequestParams = {},
) =>
  client
    .request<PublishRankingResponse>({
      url: `/rdb/v1/nil/publish-ranking-loc`,
      method: 'POST',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilReport
 * @name V1NilValuationAnomaliesList
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/nil/valuation-anomalies
 * @secure
 */
export const v1NilValuationAnomaliesList = (
  client: AxiosInstance,
  query: V1NilValuationAnomaliesListParams,
  params: RequestParams = {},
) =>
  client
    .request<NilValuationAnomalyResponsePagedData>({
      url: `/rdb/v1/nil/valuation-anomalies`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Nil
 * @name V1NilValuationDraftsList
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/nil/valuation-drafts
 * @secure
 */
export const v1NilValuationDraftsList = (
  client: AxiosInstance,
  query: V1NilValuationDraftsListParams,
  params: RequestParams = {},
) =>
  client
    .request<PersonNilValuationDraftResponsePagedData>({
      url: `/rdb/v1/nil/valuation-drafts`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Nil
 * @name V1NilVerifiedAthleteCountsList
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/nil/verified-athlete-counts
 * @secure
 */
export const v1NilVerifiedAthleteCountsList = (
  client: AxiosInstance,
  params: RequestParams = {},
) =>
  client
    .request<VerifiedAthleteMetricsResponse>({
      url: `/rdb/v1/nil/verified-athlete-counts`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1OrganizationsRosterValuationBreakdownsDetail
 * @summary  (Roles: developer, rdb5)
 * @request GET:/rdb/v1/organizations/{organizationKey}/roster-valuation-breakdowns
 * @secure
 */
export const v1OrganizationsRosterValuationBreakdownsDetail = (
  client: AxiosInstance,
  {
    organizationKey,
    ...query
  }: V1OrganizationsRosterValuationBreakdownsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<NilDashboardValuationBreakdownResponsePagedData>({
      url: `/rdb/v1/organizations/${organizationKey}/roster-valuation-breakdowns`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1PeopleNilDashboardIndustryAverageValuationBreakdownDetail
 * @summary  (Roles: registered user)
 * @request GET:/rdb/v1/people/{personKey}/nil-dashboard-industry-average-valuation-breakdown
 * @secure
 */
export const v1PeopleNilDashboardIndustryAverageValuationBreakdownDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<NilIndustryAverageResponse>({
      url: `/rdb/v1/people/${personKey}/nil-dashboard-industry-average-valuation-breakdown`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1PeopleNilDashboardSummaryDetail
 * @summary  (Roles: registered user)
 * @request GET:/rdb/v1/people/{personKey}/nil-dashboard-summary
 * @secure
 */
export const v1PeopleNilDashboardSummaryDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<NilDashboardSummaryResponse>({
      url: `/rdb/v1/people/${personKey}/nil-dashboard-summary`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1PeopleNilDashboardValuationBreakdownDetail
 * @summary  (Roles: registered user)
 * @request GET:/rdb/v1/people/{personKey}/nil-dashboard-valuation-breakdown
 * @secure
 */
export const v1PeopleNilDashboardValuationBreakdownDetail = (
  client: AxiosInstance,
  { personKey, ...query }: V1PeopleNilDashboardValuationBreakdownDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<NilDashboardValuationBreakdownResponse>({
      url: `/rdb/v1/people/${personKey}/nil-dashboard-valuation-breakdown`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1PeopleNilMoneyWantedDetail
 * @summary  (Roles: registered user)
 * @request GET:/rdb/v1/people/{personKey}/nil/money-wanted
 * @secure
 */
export const v1PeopleNilMoneyWantedDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<MoneyWantedResponse>({
      url: `/rdb/v1/people/${personKey}/nil/money-wanted`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1PeopleSettingsDetail
 * @summary  (Roles: registered user)
 * @request GET:/rdb/v1/people/{personKey}/settings
 * @secure
 */
export const v1PeopleSettingsDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonSettingsResponse>({
      url: `/rdb/v1/people/${personKey}/settings`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1PeopleSettingsUpdate
 * @summary  (Roles: registered user)
 * @request PUT:/rdb/v1/people/{personKey}/settings
 * @secure
 */
export const v1PeopleSettingsUpdate = (
  client: AxiosInstance,
  personKey: number,
  data: PersonSettingsRequest,
  params: RequestParams = {},
) =>
  client
    .request<PersonSettingsResponse>({
      url: `/rdb/v1/people/${personKey}/settings`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSocialMetric
 * @name V1PeopleSocialMetricsDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2)
 * @request GET:/rdb/v1/people/{personKey}/social-metrics
 * @secure
 */
export const v1PeopleSocialMetricsDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonSocialMetricResponsePagedData>({
      url: `/rdb/v1/people/${personKey}/social-metrics`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V1PlayersDealsDetail
 * @summary Gets all NIL Deals for a player
 * @request GET:/rdb/v1/players/{personKey}/deals
 * @secure
 */
export const v1PlayersDealsDetail = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<AdminPlayerDealResponsePagedData>({
      url: `/rdb/v1/players/${personKey}/deals`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSocialMetric
 * @name V1SocialMetricsRenovateUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2)
 * @request PUT:/rdb/v1/social-metrics/renovate
 * @secure
 */
export const v1SocialMetricsRenovateUpdate = (
  client: AxiosInstance,
  data: V1SocialMetricsRenovateUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<PersonSocialMetricResponseRenovateResponsePagedData>({
      url: `/rdb/v1/social-metrics/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboard
 * @name V1VerificationCodeCreate
 * @summary  (Roles: admin, rdb3, rdb4, rdb5, developer)
 * @request POST:/rdb/v1/verification-code/{personKey}
 * @secure
 */
export const v1VerificationCodeCreate = (
  client: AxiosInstance,
  personKey: number,
  params: RequestParams = {},
) =>
  client
    .request<DeleteResponse>({
      url: `/rdb/v1/verification-code/${personKey}`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);
