import {
  type HeadlineResponse,
  type SiteResponse,
  type SlimSiteResponse,
  type UserJwtResponse,
  v1SitesList,
} from '@on3/api';
import {
  externalApi,
  useArticle,
  useAuth,
  useSite,
  useToast,
} from '@on3/ui-lib';
import type { IUser } from '@on3/ui-lib/api/schema/custom-contracts';
import { hashEmail } from '@on3/ui-lib/utils/user';
import jwtDecode from 'jwt-decode';
import { NextRouter, useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';
import { ToastOptions } from 'react-toastify/dist/types';

import {
  readPianoCustomVariableCookie,
  subscribeToNewsletter,
} from './pianoUtil';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tp: any;
  }
}

const FB_PIXEL_ACCESS_CODE =
  'EAAT3esltZAXwBO9nQkuzeYU7D6AOnQ3DJjHVIvGPNexFdCBmQ4LmSvi5cISES9i2aGqkVTEd4FFjgUZAiYFIUqTpXljXlTs4HD8BFjEZARadwOphdkEqDSOPrZBiXVNkxX0XmmldkL4aKPMNq8EZCrj9o08raJYE91Vtw2vmSDpj7Hl5wZCPiVZA3ZArFtPDtJeliQZDZD';
const FB_PIXEL_ID = '1548897892509790';
const FB_PIXEL_API_VERSION = 'v20.0';

let hasInitialized = false;
const SANDBOX_ACCOUNT_ID = 'L12cg746su';
const PROD_ACCOUNT_ID = 'HTa7sve2pu';
const QA_ACCOUNT_ID = 'dNbRNfeqpu';
const PLAYER_PROFILE_RESOURCE = 'R_PROFILE_EDITS';
const SANDBOX_PREFIX = `https://sandbox.tinypass.com`;
const DASHBOARD_PREFIX = `https://dashboard.tinypass.com`;
const SANDBOX_SCRIPT = `${SANDBOX_PREFIX}/xbuilder/experience/load?aid=${SANDBOX_ACCOUNT_ID}`;
const QA_SCRIPT = `${DASHBOARD_PREFIX}/xbuilder/experience/load?aid=${QA_ACCOUNT_ID}`;
const PROD_SCRIPT = `${DASHBOARD_PREFIX}/xbuilder/experience/load?aid=${PROD_ACCOUNT_ID}`;
const SANDBOX_API = `${SANDBOX_PREFIX}/id/api/v1/identity/userinfo?aid=${SANDBOX_ACCOUNT_ID}`;
const QA_API = `${DASHBOARD_PREFIX}/id/api/v1/identity/userinfo?aid=${QA_ACCOUNT_ID}`;
const PROD_API = `${DASHBOARD_PREFIX}/id/api/v1/identity/userinfo?aid=${PROD_ACCOUNT_ID}`;

const domain =
  process.env.NEXT_PUBLIC_APP_ENV === 'production'
    ? 'https://www.on3.com'
    : process.env.NEXT_PUBLIC_APP_ENV === 'development' ||
        process.env.NEXT_PUBLIC_APP_ENV === 'local'
      ? 'https://www.dev.on3.com'
      : 'https://www.qa.on3.com';

interface IPianoInitializeProps {
  setUserToken: (value: UserJwtResponse) => void;
  router: NextRouter;
  currentSite?: SiteResponse;
  siteUrls?: SlimSiteResponse[];
  error: (text: string, options?: ToastOptions | undefined) => string | number;
  success: (
    text: string,
    options?: ToastOptions | undefined,
  ) => string | number;
}

interface ILoginResponse {
  user: string;
  token: string;
}
interface ICheckoutResponse {
  chargeAmount: number;
  chargeCurrency: string;
  cookie_domain: string;
  email: string;
  expires: string;
  paymentId: string;
  promotionId: string;
  rid: string;
  startedAt: string;
  termConversionId: string;
  termId: string;
  token_list: string;
  uid: string;
  user_token: string;
}
interface AccessResponseProps {
  data: {
    access_id: string;
    parent_access_id: string;
    granted: boolean;
    user: any; //todo: fill out
    resource: any; //todo: fillout
    expire_date: string;
    start_date: string;
    can_revoke_access: boolean;
    custom_data: string;
  }[];
}

interface ICustomEventProps {
  eventName: string;
  state: string;
  params: {
    params?: string;
    email?: string;
  };
}

function removeRegWallAndBlocker() {
  const article = document.querySelector('#article-1') as HTMLElement | null;
  const regWall = document.querySelector('#pianoRegWall') as HTMLElement | null;
  const blocker = document.querySelector('#blocker') as HTMLElement | null;

  if (article?.classList.contains('regMetered')) {
    article && article.classList.remove('regMetered');
    regWall && (regWall.style.display = 'none');
    blocker && (blocker.style.display = 'none');
    document.body.style.overflow = 'scroll';
    document.documentElement.style.overflow = 'scroll';
  }
}

async function getUserIP() {
  try {
    const response = await fetch('https://api64.ipify.org?format=json');
    const data = await response.json();

    return data.ip;
  } catch (error) {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();

    return data.ip;
  }
}

function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    const part = parts.pop();

    return part ? part.split(';').shift() : undefined;
  }

  return undefined;
}

function getFbcFromCookie() {
  const fbcCookie = getCookie('_fbc');

  return fbcCookie ? fbcCookie : null;
}

function getFbpFromCookie() {
  const fbcCookie = getCookie('_fbp');

  return fbcCookie ? fbcCookie : null;
}

async function trackPurchase(
  conversion: ICheckoutResponse,
  userKey: number,
  userEmail: string,
  token: UserJwtResponse,
) {
  let encodedEmail: string | null = null;
  const userObj = token?.token ? jwtDecode<IUser>(token?.token) : null;

  if (userEmail) {
    try {
      encodedEmail = await hashEmail(userEmail);
    } catch {
      encodedEmail = null;
    }
  }

  const ip = await getUserIP();
  const userAgent = navigator.userAgent;
  const fbc = getFbcFromCookie();
  const fbp = getFbpFromCookie();

  // Additional Conversions API tracking
  const data = {
    event_name: 'Purchase',
    event_time: Math.floor(Date.now() / 1000),
    action_source: 'website',
    user_data: {
      em: [encodedEmail],
      client_ip_address: ip,
      client_user_agent: userAgent,
      fbc,
      fbp,
      external_id: userKey,
    },
    custom_data: {
      currency: conversion?.chargeCurrency,
      value: conversion?.chargeAmount,
      resource: conversion?.rid,
      term: conversion?.termId,
      promotion: conversion?.promotionId,
      expires: conversion?.expires,
    },
  };

  fetch(
    `https://graph.facebook.com/${FB_PIXEL_API_VERSION}/${FB_PIXEL_ID}/events?access_token=${FB_PIXEL_ACCESS_CODE}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: [data] }),
    },
  );

  window?.gtag('event', 'purchase', {
    transaction_id: conversion?.paymentId,
    value: conversion?.chargeAmount,
    currency: conversion?.chargeCurrency,
    userId: conversion?.uid,
    expires: conversion?.expires,
    author: window?.tp?.contentAuthor,
    emailAddress: userEmail,
    client_ip_address: ip,
    userKey,
    items: [
      {
        item_id: conversion?.termId,
        item_name: conversion?.termId,
        affiliation: conversion?.rid,
        price: conversion?.chargeAmount,
        quantity: 1,
      },
    ],
  });
}

const pianoInitialize = ({
  setUserToken,
  router,
  currentSite,
  error,
  success,
}: IPianoInitializeProps) => {
  const isProd = process.env.NEXT_PUBLIC_APP_ENV === 'production';
  const isQa = process.env.NEXT_PUBLIC_APP_ENV === 'qa';
  const isSandbox = !isProd && !isQa;
  const siteName = currentSite?.name || 'On3';
  const source = currentSite?.isChannel
    ? 'channel'
    : currentSite?.isTeam
      ? 'team'
      : 'network';

  const newsletterSiteKey =
    (currentSite?.isChannel && !!currentSite?.siblingSiteKey
      ? currentSite?.siblingSiteKey
      : currentSite?.key) || 44;

  !isProd && console.debug('piano initialized');
  window.tp = window.tp || [];
  window.tp.push([
    'setAid',
    isProd ? PROD_ACCOUNT_ID : isQa ? QA_ACCOUNT_ID : SANDBOX_ACCOUNT_ID,
  ]);

  const apiURL =
    process.env.NEXT_PUBLIC_APP_ENV === 'production'
      ? PROD_API
      : process.env.NEXT_PUBLIC_APP_ENV === 'qa'
        ? QA_API
        : SANDBOX_API;

  if (isProd) {
    window.tp.push(['setPianoIdUrl', 'https://auth.on3.com/']);
  }

  window.tp.push(['setSandbox', isSandbox]);
  window.tp.push(['setUsePianoIdUserProvider', true]);
  window.tp.push(['setZone', 'Web']);
  window.tp.push(['setDebug', isSandbox]);
  window.tp.push(['setApplePayMerchantId', 'acct_1IpaWJHBOGNBmoSl']);

  window.tp.push([
    'addHandler',
    'checkoutComplete',
    (conversion: ICheckoutResponse) => {
      const token = window?.tp.user.getProvider().getToken();

      window.tp.pianoId.isUserValid() &&
        externalApi
          .get('/users/v1/users/piano-access?resync=true', {
            headers: {
              Authorization: `Bearer ${token}`,
              withCredentials: true,
            },
          })
          .then(async ({ data }: { data: UserJwtResponse }) => {
            setUserToken(data);
            const isJoin = router.asPath.includes('/join/');
            const homePath = `${currentSite?.isTeam ? currentSite?.url : ''}/`;
            const isProfileCheckout =
              conversion.rid === PLAYER_PROFILE_RESOURCE;
            let verifyPath = homePath;

            if (isProfileCheckout) {
              const currentPath = window?.tp?.customVariables?.returnToUrl;
              const queryString = currentPath.split('?')[1];
              const params = new URLSearchParams(queryString);
              const key = params.get('key');
              const nonce = params.get('nonce');

              verifyPath = `/get-recruited/verify?key=${key}&nonce=${nonce}`;
            }

            const userId = data?.userId || 0;
            const email = data?.email || '';

            try {
              trackPurchase(conversion, userId, email, data);
              subscribeToNewsletter({
                email,
                newsletterSiteKey,
                campaignSource: `${siteName}-${source}-piano-modal`,
                campaign: 'piano',
              });
            } catch (e) {
              console.debug(e, 'error firing conversion event');
            } finally {
              isJoin && router.push(homePath);
              isProfileCheckout && router.push(verifyPath);
            }
          });
    },
  ]);

  window.tp.push([
    'addHandler',
    'completeUpgradePurchase',
    (conversion: ICheckoutResponse) => {
      const token = window?.tp.user.getProvider().getToken();

      window.tp.pianoId.isUserValid() &&
        externalApi
          .get('/users/v1/users/piano-access?resync=true', {
            headers: {
              Authorization: `Bearer ${token}`,
              withCredentials: true,
            },
          })
          .then((response) => {
            setUserToken(response.data);
            const isJoin = router.asPath.includes('/join/');
            const homePath = `${currentSite?.isTeam ? currentSite?.url : ''}/`;

            try {
              window?.gtag('event', 'purchase', {
                transaction_id: conversion?.paymentId,
                value: conversion?.chargeAmount,
                currency: conversion?.chargeCurrency,
                userId: conversion?.uid,
                expires: conversion?.expires,
                author: window?.tp?.contentAuthor,
                items: [
                  {
                    item_id: conversion?.termId,
                    item_name: conversion?.termId,
                    affiliation: conversion?.rid,
                    price: conversion?.chargeAmount,
                    quantity: 1,
                  },
                ],
              });
              window?.fbq('track', 'Purchase', {
                content_ids: conversion?.termId,
                content_category: conversion?.rid,
                currency: conversion?.chargeCurrency,
                value: conversion?.chargeAmount,
              });
            } catch (e) {
              console.debug(e, 'error toggling container');
            }

            isJoin && router.push(homePath);
          });
    },
  ]);

  window.tp.push([
    'addHandler',
    'checkoutClose',
    (event: ICustomEventProps) => {
      switch (event.state) {
        case 'voucherRedemptionCompleted': {
          const homePath = `${currentSite?.isTeam ? currentSite?.url : ''}/`;

          router.push(homePath);

          break;
        }
      }
    },
  ]);

  window.tp.push([
    'addHandler',
    'customEvent',
    async (event: ICustomEventProps) => {
      switch (event.eventName) {
        case 'meter-collapse': {
          try {
            const meterContainer = document.querySelector('#pianoLeft');

            meterContainer?.classList.toggle('collapsed');
          } catch (e) {
            console.debug(e, 'error toggling container');
          }

          break;
        }
      }
    },
  ]);

  window.tp.push([
    'init',
    () => {
      window.tp.setExcludedGAEvents({
        experienceExecute: true,
      });
      window.tp.setGA4Config({
        measurementId: 'G-D6C0XT55DS',
      });
      window.tp.pianoId.init({
        loggedIn(data: ILoginResponse) {
          const hasReturn = router?.query?.returnto;
          const isLogin = router.asPath.includes('/login/');
          const homePath = `${currentSite?.isTeam ? currentSite?.url : ''}/`;

          window.tp.pianoId.isUserValid() &&
            externalApi
              .get('/users/v1/users/piano-access', {
                headers: {
                  Authorization: `Bearer ${data?.token}`,
                  withCredentials: true,
                },
              })
              .then(
                async ({ data: responseData }: { data: UserJwtResponse }) => {
                  setUserToken(responseData);
                  hasReturn && router.push(router?.query?.returnto as string);
                  !hasReturn && isLogin && router.push(homePath);

                  removeRegWallAndBlocker();

                  if (responseData.email) {
                    const encodedEmail = await hashEmail(responseData.email);

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: 'login',
                      email: encodedEmail,
                      userKey: `${responseData?.userId || null}`,
                    });
                  }
                },
              );

          const params = {
            active: true,
            user_token: data.token,
            user_provider: 'piano_id',
            aid: isProd
              ? PROD_ACCOUNT_ID
              : isQa
                ? QA_ACCOUNT_ID
                : SANDBOX_ACCOUNT_ID,
          };

          const callback = (response: AccessResponseProps) => {
            window.tp.push([
              'addHandler',
              'experienceExecute',
              (e: any) => {
                const now = Date.now() / 1000;
                const mostRecentExpired = e.accessList
                  .filter((s: any) => s.expireDate < now) // Filter out objects with future expireDates
                  .sort((a: any, b: any) => b.expireDate - a.expireDate)[0]; // Sort by expireDate in descending order and get the first element

                if (!mostRecentExpired?.rid) {
                  return;
                }

                v1SitesList(externalApi, { resourceId: mostRecentExpired?.rid })
                  .then((responseData) => {
                    if (responseData.length < 1) {
                      return;
                    }

                    const expiredResource = responseData?.[0];
                    const hasSiblingSite = expiredResource?.siblingSiteKey;
                    const siblingSiteUrl =
                      hasSiblingSite && expiredResource.isChannel
                        ? expiredResource.url?.replace('college', 'teams')
                        : '';
                    const isOn3 = expiredResource?.resourceId === 'R44';

                    const winbackUrl = expiredResource?.allowSubs
                      ? `${expiredResource?.url}/winback/`
                      : isOn3
                        ? '/winback/'
                        : `${siblingSiteUrl}/winback/`;

                    //TODO: stop using this for asset lookups
                    const name = expiredResource?.organization?.name || '';
                    const teamName =
                      name?.replace(/\s+/g, '-').toLocaleLowerCase() || 'on3';
                    const siteLogo =
                      expiredResource?.key === 401
                        ? 'https://on3static.com/sites/softball-logo-full.png?v=26'
                        : expiredResource?.isTeam ||
                            (expiredResource?.isChannel &&
                              !!expiredResource.siblingSiteKey)
                          ? `https://on3static.com/sites/${teamName}-logo-full.png?v=26`
                          : 'https://on3static.com/static/on3/on3-logo.png';
                    const winbackResource = {
                      rid: expiredResource?.resourceId,
                      winbackUrl,
                      slug: expiredResource?.slug,
                      siteLogo,
                    };

                    window.tp.push([
                      'setCustomVariable',
                      'winbackResource',
                      winbackResource,
                    ]);
                  })
                  .catch((err) => {
                    console.error('error', err);
                  });
              },
            ]);
            if (
              response?.data.sort((a, b) =>
                a.expire_date < b.expire_date ? -1 : 1,
              )[0]?.granted
            ) {
              const expDate = response?.data[0]?.expire_date;
              const curDate = Date.now() / 1000;
              const daysLeft = Math.floor((+expDate - curDate) / 86400) + 1;

              const formData: {
                uid: string;
                form_name: string;
                custom_field_values: {
                  field_name: string;
                  value: string | number;
                }[];
              } = {
                uid: window.tp.pianoId.getUser().uid,
                form_name: 'sub_expires_soon',
                custom_field_values: [],
              };
              const array = [];

              array.push({
                field_name: 'sub_days_left',
                value: daysLeft,
              });
              formData['custom_field_values'] = array;
              window.tp.push(['setCustomVariable', 'subDaysLeft', daysLeft]);

              fetch(
                `${apiURL}&access_token=${window.tp.pianoId.getToken()}&lang=en_US`,
                {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                  },
                  body: JSON.stringify(formData),
                },
              );
            }
          };

          try {
            window.tp.api.callApi('/access/list', params, callback);
          } catch (e) {
            console.error('error', e);
          }
        },
        registrationSuccess(data: ILoginResponse) {
          function updateDataLayer({
            encodedEmail,
            userId,
          }: {
            encodedEmail: string;
            userId: number;
          }) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'login',
              email: encodedEmail,
              userKey: `${userId || null}`,
            });
          }

          window.tp.pianoId.isUserValid() &&
            externalApi
              .get('/users/v1/users/piano-access', {
                headers: {
                  Authorization: `Bearer ${data?.token}`,
                  withCredentials: true,
                },
              })

              .then(
                async ({ data: responseData }: { data: UserJwtResponse }) => {
                  setUserToken(responseData);
                  removeRegWallAndBlocker();

                  // Hash and send the email to dataLayer
                  const email = responseData?.email?.toString() || '';
                  const userId = responseData.userId || 0;

                  if (email) {
                    const encodedEmail = await hashEmail(email);

                    updateDataLayer({ encodedEmail, userId });
                  }

                  try {
                    subscribeToNewsletter({
                      email,
                      newsletterSiteKey,
                      campaignSource: `${siteName}-${source}-piano-modal`,
                      campaign: 'piano',
                    });
                  } catch (e) {
                    console.error('Newsletter subscription failed:', e);
                  }
                },
              );
        },
        loggedOut() {
          console.debug('user logged out');
        },
      });
    },
  ]);
};

const pianoPageview = ({
  user,
  article,
  path,
  currentSite,
}: {
  user?: IUser;
  article?: HeadlineResponse | null;
  path?: string;
  currentSite?: SiteResponse;
}) => {
  const {
    a: userAlias,
    st: userStatus,
    pe: promotionEligble,
    has: hasActiveSubscription,
    pl: plans,
  } = user || {};
  const subType = userStatus ? userStatus : userAlias ? 'registered' : 'guest';
  const cookieValues = readPianoCustomVariableCookie();

  window.tp = window.tp || [];

  // TODO Questions for Piano,
  // Post date of modified date
  const contentCreated = article?.postDate;
  const contentId = article?.key;
  // Author Name of Author Key
  const hasSiblingSite = currentSite?.siblingSiteKey;
  const siblingSiteUrl =
    hasSiblingSite && currentSite.isChannel
      ? currentSite.url?.replace('college', 'teams')
      : '';
  const contentAuthor = article?.author?.name;
  const contentSection = article?.primaryCategory?.name;

  const tags = article?.tags?.map((tag) => tag?.name?.replace(/,/g, ''));
  const url = `${domain}${path}`;
  //TODO: stop using this for asset lookups
  const name = currentSite?.organization?.name || '';
  const teamName = name?.replace(/\s+/g, '-').toLocaleLowerCase() || 'on3';
  const siteUrl = currentSite?.key === 44 ? '' : currentSite?.url;
  const isOn3 = currentSite?.resourceId === 'R44';

  const subscribeUrl = currentSite?.allowSubs
    ? `${currentSite?.url}/join/`
    : isOn3
      ? '/subscribe/'
      : `${siblingSiteUrl}/join/`;

  const activeSubscription = !!(
    hasActiveSubscription ||
    (plans && plans?.length > 0)
  );
  const siteLogo =
    currentSite?.key === 401
      ? 'https://on3static.com/sites/softball-logo-full.png?v=26'
      : currentSite?.isTeam ||
          (currentSite?.isChannel && !!currentSite.siblingSiteKey)
        ? `https://on3static.com/sites/${teamName}-logo-full.png?v=26`
        : 'https://on3static.com/static/on3/on3-logo.png';
  const linkColor = currentSite?.linkColor || currentSite?.primaryColor;
  const siteLogoAlt =
    currentSite?.key === 401
      ? 'https://on3static.com/sites/softball-logo.png?v=26'
      : currentSite?.isTeam ||
          (currentSite?.isChannel && !!currentSite.siblingSiteKey)
        ? `https://on3static.com/sites/${teamName}-logo.png?v=26`
        : 'https://on3static.com/static/on3/on3-logo.png';
  const orgName =
    currentSite?.organization?.knownAs ?? currentSite?.organization?.name;
  const displayName =
    currentSite?.key === 401
      ? 'Softball America'
      : currentSite?.isNational
        ? 'College'
        : `${orgName} ${currentSite?.organization?.mascot}`;

  window.tp.push(['setContentCreated', contentCreated]);
  window.tp.push(['setContentAuthor', contentAuthor]);
  window.tp.push(['setContentSection', contentSection]);
  window.tp.push(['setContentId', contentId]);
  window.tp.push(['setTags', tags]);
  window.tp.push(['setCustomVariable', 'userState', subType]);
  window.tp.push(['setCustomVariable', 'activeScription', activeSubscription]);
  window.tp.push([
    'setCustomVariable',
    'activeSubscription',
    activeSubscription,
  ]);
  window.tp.push([
    'setCustomVariable',
    'subscribeText',
    currentSite?.subscribeText,
  ]);
  window.tp.push(['setCustomVariable', 'hasSiblingSite', hasSiblingSite]);
  window.tp.push(['setCustomVariable', 'siteType', currentSite?.type]);
  window.tp.push(['setCustomVariable', 'promotionEligble', promotionEligble]);
  window.tp.push(['setCustomVariable', 'siteName', currentSite?.siteName]);
  window.tp.push(['setCustomVariable', 'organization', orgName]);
  window.tp.push(['setCustomVariable', 'siteLogo', siteLogo]);
  window.tp.push(['setCustomVariable', 'siteLogoAlt', siteLogoAlt]);
  window.tp.push(['setCustomVariable', 'siteKey', currentSite?.key]);
  window.tp.push(['setCustomVariable', 'siteColor', currentSite?.primaryColor]);
  window.tp.push(['setCustomVariable', 'siteLinkColor', linkColor]);
  window.tp.push(['setCustomVariable', 'siteUrl', siteUrl]);
  window.tp.push(['setCustomVariable', 'returnToUrl', path]);
  window.tp.push(['setCustomVariable', 'subscribeUrl', subscribeUrl]);
  window.tp.push([
    'setCustomVariable',
    'channelUrl',
    currentSite?.url?.replace('/teams', '/college'),
  ]);
  window.tp.push([
    'setCustomVariable',
    'resource',
    currentSite?.resourceId || 'null',
  ]);
  window.tp.push(['setCustomVariable', 'organizationDisplayName', displayName]);
  for (const i in cookieValues) {
    window.tp.push(['setCustomVariable', i, cookieValues[i]]);
  }

  window.tp.push(['setPageURL', url]);

  window.tp.push(['init', () => window.tp.experience.init()]);

  // TODO: look at calling this conditionally
  window?.tp?.experience?.execute();
};

export const Piano = () => {
  const { article } = useArticle();
  const { currentSite, siteUrls } = useSite();
  const { user, setUserToken } = useAuth();
  const { asPath: path } = useRouter();
  const router = useRouter();
  const { error, success } = useToast();

  const scriptURL =
    process.env.NEXT_PUBLIC_APP_ENV === 'production'
      ? PROD_SCRIPT
      : process.env.NEXT_PUBLIC_APP_ENV === 'qa'
        ? QA_SCRIPT
        : SANDBOX_SCRIPT;

  useEffect(() => {
    if (!hasInitialized) {
      pianoInitialize({
        setUserToken,
        router,
        currentSite,
        siteUrls,
        error,
        success,
      });
    }

    hasInitialized = true;

    pianoPageview({ user, article, path, currentSite });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return <Script src={scriptURL} strategy="beforeInteractive" />;
};
