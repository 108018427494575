import type { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface ApiRenovateRankingPercentsRequest {
  /** @format double */
  on3Percent?: number | null;
  /** @format double */
  espnPercent?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  key: number;
  /** @format double */
  rivalsPercent?: number | null;
  /** @format double */
  tfsSportsPercent?: number | null;
}

/** Specifies the kind of asset and Asset object is. */
export enum AssetClasses {
  Unknown = 'Unknown',
  Image = 'Image',
  Video = 'Video',
}

export interface AssetResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export interface AttributeResponse {
  abbr?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
}

export enum Category {
  Undetermined = 'Undetermined',
  Highlights = 'Highlights',
  Training = 'Training',
  Experts = 'Experts',
  Conversations = 'Conversations',
  Camp = 'Camp',
}

export interface CityResponse {
  country?: CountryResponse;
  /** @format int32 */
  countryKey?: number | null;
  /** @format int32 */
  key: number;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  name?: string | null;
  postalCode?: string | null;
  regionKey?: string | null;
  state?: StateResponse;
  /** @format int32 */
  stateKey?: number | null;
}

export enum ClassificationType {
  Unknown = 'Unknown',
  League = 'League',
  State = 'State',
  Conference = 'Conference',
  Division = 'Division',
  Association = 'Association',
  Region = 'Region',
  District = 'District',
  Class = 'Class',
  Metro = 'Metro',
  Section = 'Section',
}

export interface CountryResponse {
  abbreviation?: string;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface CreateRankingRequest {
  autoPublish: boolean;
  createVersion: boolean;
  /**
   * @format int32
   * @min 0
   * @max 10
   */
  draftRankStrength: number;
  /** @format int32 */
  key?: number | null;
  organizationType: OrganizationType;
  /** @format int32 */
  sportKey: number;
  type: RankingType;
  /** @format int32 */
  year: number;
}

export interface ImageResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export interface ImportOcrdRanksReq {
  organizationType: OrganizationType;
  /** @minItems 1 */
  ranks: OcrdRankImportReq[];
  source: MappingSource;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  sportKey: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  year: number;
}

export interface IndustryComparisonResponse {
  /** @format int64 */
  nilValue?: number | null;
  person?: PlayerResponse;
  rating?: RatingResponse;
  ratings?: PlayerRatingAllResponse[];
  rpm?: RpmPercentResponse[] | null;
  status?: RecStatusResponse;
}

export interface IndustryComparisonResponsePagedData {
  list?: IndustryComparisonResponse[];
  pagination?: Pagination;
}

export interface KeyUrlBase {
  fullName?: string;
  /** @format int64 */
  key: number;
  slug?: string | null;
  url?: string | null;
}

export enum MappingSource {
  Massey = 'Massey',
  MaxPreps = 'MaxPreps',
  CalPreps = 'CalPreps',
  Nfhs = 'Nfhs',
  Nces = 'Nces',
  TfsSports = 'TfsSports',
  Si = 'Si',
  Espn = 'Espn',
  TfsSportsCoach = 'TfsSportsCoach',
  Rivals = 'Rivals',
  CollegeContact = 'CollegeContact',
}

export interface NilDashboardValuationBreakdownResponse {
  /** @format int64 */
  brandValue: number;
  /** @format double */
  exposureRating?: number | null;
  /** @format double */
  influenceRating?: number | null;
  nilStatus: NilStatuses;
  /** @format int64 */
  nilValue?: number | null;
  /** @format double */
  performanceRating?: number | null;
  /** @format int64 */
  personKey: number;
  pickedOrganization?: OrganizationResponse;
  pickedValuation?: NilDashboardValuationBreakdownResponse;
  /** @format double */
  pieAverage?: number | null;
  playerServiceRatingCount?: PlayerServiceRatingCountResponse;
  /** @format int64 */
  rosterValue?: number | null;
  /** @format int64 */
  valuation?: number | null;
}

export enum NilStatuses {
  Normal = 'Normal',
  Pending = 'Pending',
  Suspended = 'Suspended',
  Private = 'Private',
  Hidden = 'Hidden',
  Show = 'Show',
  Frozen = 'Frozen',
  Final = 'Final',
}

export interface OcrdRankImportReq {
  city?: string | null;
  classificationType?: ClassificationType;
  /** @format int32 */
  rank?: number | null;
  /** @format double */
  rating?: number | null;
  school?: string | null;
  sourceKey?: string | null;
  state?: string | null;
}

export interface OrdAdminPreviewManageResponse {
  /** @format double */
  appliedAverageConsensusRating?: number | null;
  /** @format double */
  appliedAverageRating?: number | null;
  /** @format int32 */
  appliedCommits?: number | null;
  /** @format double */
  appliedTotalConsensusRating?: number | null;
  /** @format double */
  appliedTotalRating?: number | null;
  /** @format int32 */
  change?: number | null;
  /** @format int32 */
  commits?: number | null;
  /** @format int32 */
  consensusChange?: number | null;
  /** @format int32 */
  consensusFiveStars?: number | null;
  /** @format int32 */
  consensusFourStars?: number | null;
  /** @format int32 */
  consensusThreeStars?: number | null;
  /** @format double */
  deductions?: number | null;
  deductionsDescription?: string | null;
  /** @format int32 */
  fiveStars?: number | null;
  /** @format int32 */
  fourStars?: number | null;
  /** @format int64 */
  key: number;
  organization?: OrganizationBase;
  /** @format int32 */
  overallConsensusRank?: number | null;
  /** @format int32 */
  overallRank?: number | null;
  /** @format int32 */
  rankingKey: number;
  /** @format int32 */
  threeStars?: number | null;
}

export interface OrdAdminPreviewManageResponsePagedData {
  list?: OrdAdminPreviewManageResponse[];
  pagination?: Pagination;
}

export interface OrgCompositeRankingDraftRes {
  /** @format int32 */
  calPrepsOverallRank?: number | null;
  /** @format double */
  calPrepsRating?: number | null;
  /** @format double */
  calPrepsScore?: number | null;
  /** @format int32 */
  calPrepsStateRank?: number | null;
  /** @format date-time */
  calPrepsUpdated?: string | null;
  city?: CityResponse;
  /** @format int32 */
  cityKey?: number | null;
  /** @format int32 */
  compositeOverallRank?: number | null;
  /** @format double */
  compositeScore?: number | null;
  /** @format int32 */
  compositeStateRank?: number | null;
  /** @format date-time */
  compositeUpdated?: string | null;
  /** @format int32 */
  espnOverallRank?: number | null;
  /** @format double */
  espnRating?: number | null;
  /** @format double */
  espnScore?: number | null;
  /** @format int32 */
  espnStateRank?: number | null;
  /** @format date-time */
  espnUpdated?: string | null;
  /** @format int64 */
  key: number;
  /** @format int32 */
  masseyOverallRank?: number | null;
  /** @format double */
  masseyRating?: number | null;
  /** @format double */
  masseyScore?: number | null;
  /** @format int32 */
  masseyStateRank?: number | null;
  /** @format date-time */
  masseyUpdated?: string | null;
  /** @format int32 */
  maxPrepsOverallRank?: number | null;
  /** @format double */
  maxPrepsRating?: number | null;
  /** @format double */
  maxPrepsScore?: number | null;
  /** @format int32 */
  maxPrepsStateRank?: number | null;
  /** @format date-time */
  maxPrepsUpdated?: string | null;
  organization?: OrganizationResponse;
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  rankingKey: number;
  record?: string | null;
  /** @format int32 */
  siOverallRank?: number | null;
  /** @format double */
  siRating?: number | null;
  /** @format double */
  siScore?: number | null;
  /** @format int32 */
  siStateRank?: number | null;
  /** @format date-time */
  siUpdated?: string | null;
  state?: StateResponse;
  /** @format int32 */
  stateKey?: number | null;
}

export interface OrgCompositeRankingDraftResRelatedRankingResponseRelatedPagedData {
  list?: OrgCompositeRankingDraftRes[];
  pagination?: Pagination;
  relatedModel?: RelatedRankingResponse;
}

export interface OrgCompositeRankingRes {
  /** @format int32 */
  calPrepsOverallRank?: number | null;
  /** @format double */
  calPrepsRating?: number | null;
  /** @format double */
  calPrepsScore?: number | null;
  /** @format int32 */
  calPrepsStateRank?: number | null;
  /** @format date-time */
  calPrepsUpdated?: string | null;
  city?: CityResponse;
  /** @format int32 */
  cityKey?: number | null;
  /** @format int32 */
  compositeOverallRank?: number | null;
  /** @format double */
  compositeScore?: number | null;
  /** @format int32 */
  compositeStateRank?: number | null;
  /** @format date-time */
  compositeUpdated?: string | null;
  /** @format int32 */
  espnOverallRank?: number | null;
  /** @format double */
  espnRating?: number | null;
  /** @format double */
  espnScore?: number | null;
  /** @format int32 */
  espnStateRank?: number | null;
  /** @format date-time */
  espnUpdated?: string | null;
  /** @format int64 */
  key: number;
  /** @format int32 */
  masseyOverallRank?: number | null;
  /** @format double */
  masseyRating?: number | null;
  /** @format double */
  masseyScore?: number | null;
  /** @format int32 */
  masseyStateRank?: number | null;
  /** @format date-time */
  masseyUpdated?: string | null;
  /** @format int32 */
  maxPrepsOverallRank?: number | null;
  /** @format double */
  maxPrepsRating?: number | null;
  /** @format double */
  maxPrepsScore?: number | null;
  /** @format int32 */
  maxPrepsStateRank?: number | null;
  /** @format date-time */
  maxPrepsUpdated?: string | null;
  organization?: OrganizationResponse;
  /** @format int32 */
  organizationKey: number;
  /** @format int32 */
  rankingKey: number;
  record?: string | null;
  /** @format int32 */
  siOverallRank?: number | null;
  /** @format double */
  siRating?: number | null;
  /** @format double */
  siScore?: number | null;
  /** @format int32 */
  siStateRank?: number | null;
  /** @format date-time */
  siUpdated?: string | null;
  state?: StateResponse;
  /** @format int32 */
  stateKey?: number | null;
}

export interface OrgCompositeRankingResRelatedRankingResponseRelatedPagedData {
  list?: OrgCompositeRankingRes[];
  pagination?: Pagination;
  relatedModel?: RelatedRankingResponse;
}

export interface OrganizationBase {
  abbreviation?: string | null;
  asset?: AssetResponse;
  assetUrl?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  mascot?: string | null;
  name?: string;
  primaryColor?: string | null;
  slug?: string | null;
}

export interface OrganizationRankingResponse {
  /** @format double */
  dispayOn3Score?: number | null;
  /** @format double */
  appliedAverageConsensusRating?: number | null;
  /** @format double */
  appliedAverageRating?: number | null;
  /** @format int32 */
  appliedCommits?: number | null;
  /** @format double */
  appliedTotalConsensusRating?: number | null;
  /** @format double */
  appliedTotalRating?: number | null;
  /** @format double */
  averageNilValue?: number | null;
  /** @format int32 */
  commits?: number | null;
  /** @format int32 */
  conferenceConsensusRank?: number | null;
  /** @format int32 */
  conferenceRank?: number | null;
  /** @format int32 */
  consensusFiveStars?: number | null;
  /** @format int32 */
  consensusFourStars?: number | null;
  /** @format int32 */
  consensusThreeStars?: number | null;
  /** @format double */
  deductions?: number | null;
  deductionsDescription?: string | null;
  /** @format double */
  dispayConsensusScore?: number | null;
  /** @format int32 */
  fiveStars?: number | null;
  /** @format int32 */
  fourStars?: number | null;
  /** @format int64 */
  key: number;
  organization?: OrganizationBase;
  /** @format int32 */
  overallConsensusRank?: number | null;
  /** @format int32 */
  overallRank?: number | null;
  /** @format int32 */
  threeStars?: number | null;
}

export interface OrganizationResponse {
  abbreviation?: string | null;
  assetUrl?: string | null;
  defaultAsset?: AssetResponse;
  division?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  knownAs?: string | null;
  mascot?: string | null;
  name?: string;
  orgType?: string;
  orgTypeEnum: OrganizationType;
  primaryColor?: string | null;
  siteKeys?: number[] | null;
  slug?: string | null;
  urlSlug?: string | null;
}

export enum OrganizationType {
  Unknown = 'Unknown',
  HighSchool = 'HighSchool',
  JuniorCollege = 'JuniorCollege',
  College = 'College',
  Professional = 'Professional',
  PrepSchool = 'PrepSchool',
  Developmental = 'Developmental',
}

export interface Pagination {
  /** @format int32 */
  count: number;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  itemsPerPage: number;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  /** @format int32 */
  pageCount: number;
}

export interface PersonResponse {
  /** @format int32 */
  age?: number | null;
  athleteVerified: boolean;
  classRank?: string;
  /**
   * The person's high school class year
   * @format int32
   */
  classYear?: number | null;
  defaultAsset?: ImageResponse;
  /** The url of the person's default asset */
  defaultAssetUrl?: string | null;
  defaultSport?: SportResponseRecord;
  /** Did the person enroll early */
  earlyEnrollee: boolean;
  /** Did the person sign early */
  earlySignee: boolean;
  /** The person's height in Feet and Inches */
  formattedHeight?: string | null;
  /**
   * The person's height in inches
   * @format double
   */
  height?: number | null;
  highSchool?: OrganizationResponse;
  /** The name of the High School the person attended */
  highSchoolName?: string | null;
  /** The name of the person's hometown */
  homeTownName?: string | null;
  /** @format int64 */
  key: number;
  /** The full name of the person */
  name?: string;
  /** The abbreviation of the person's primary position */
  positionAbbreviation?: string | null;
  predictions?: RpmResponse[] | null;
  prospectVerified: boolean;
  rating?: RatingExtendedResponse;
  /** @format int64 */
  recruitmentKey?: number | null;
  /** The slug of the person */
  slug?: string;
  status?: RecruitmentStatusResponse;
  /**
   * The person's weight in pounds
   * @format double
   */
  weight?: number | null;
}

export interface PersonSportRankingDraftResponse {
  /** @format double */
  averageRank?: number | null;
  /** @format int32 */
  consensusOverallRank?: number | null;
  /** @format int32 */
  consensusPositionRank?: number | null;
  /** @format double */
  consensusRating?: number | null;
  /** @format int32 */
  consensusStateRank?: number | null;
  /** @format int32 */
  consensusStrength?: number | null;
  /** @format int32 */
  dateModified: number;
  /** @format int32 */
  espnLastUpdated?: number | null;
  /** @format int32 */
  espnOverallRank?: number | null;
  /** @format int32 */
  espnPositionRank?: number | null;
  /** @format double */
  espnRating?: number | null;
  /** @format int32 */
  espnStars?: number | null;
  /** @format int32 */
  espnStateRank?: number | null;
  /** @format int64 */
  key: number;
  /** @format double */
  normalizedEspnRating?: number | null;
  /** @format double */
  normalizedRivalsRating?: number | null;
  /** @format double */
  normalizedTfsSportsRating?: number | null;
  /** @format int32 */
  order?: number | null;
  /** @format int32 */
  overallRank?: number | null;
  /** @format int64 */
  personSportKey: number;
  /** @format int64 */
  personSportOrganizationKey?: number | null;
  position?: PositionResponse;
  /** @format int32 */
  positionKey?: number | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format int32 */
  rankingKey: number;
  /** @format double */
  rating?: number | null;
  /** @format int64 */
  recruitmentKey?: number | null;
  /** @format int32 */
  rivalsLastUpdated?: number | null;
  /** @format int32 */
  rivalsOverallRank?: number | null;
  /** @format int32 */
  rivalsPositionRank?: number | null;
  /** @format double */
  rivalsRating?: number | null;
  /** @format int32 */
  rivalsStars?: number | null;
  /** @format int32 */
  rivalsStateRank?: number | null;
  /** @format int32 */
  stars?: number | null;
  /** @format int32 */
  stateKey?: number | null;
  /** @format int32 */
  stateRank?: number | null;
  /** @format int32 */
  tfsSportsLastUpdated?: number | null;
  /** @format int32 */
  tfsSportsOverallRank?: number | null;
  /** @format int32 */
  tfsSportsPositionRank?: number | null;
  /** @format double */
  tfsSportsRating?: number | null;
  /** @format int32 */
  tfsSportsStateRank?: number | null;
}

export interface PersonSportRankingDraftResponsePagedData {
  list?: PersonSportRankingDraftResponse[];
  pagination?: Pagination;
}

export interface PersonSportRankingResponse {
  collegeTeam?: OrganizationBase;
  /** @format int32 */
  consensusOverallRank?: number | null;
  /** @format int32 */
  consensusPositionRank?: number | null;
  /** @format int32 */
  consensusStateRank?: number | null;
  fiveStarPlus: boolean;
  highSchoolRating?: RatingExtendedResponse;
  /** @format int32 */
  jerseyNumber?: number | null;
  /** @format int64 */
  key: number;
  nearlyFiveStarPlus: boolean;
  /** @format double */
  nilValue?: number | null;
  /** @format int32 */
  overallRank?: number | null;
  person?: PersonResponse;
  positionAbbreviation?: string | null;
  /** @format int32 */
  positionKey?: number | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format int32 */
  rankingKey: number;
  ratings?: PlayerRatingAllResponse[];
  /** @format int64 */
  recruitmentKey?: number | null;
  stateAbbreviation?: string | null;
  /** @format int32 */
  stateKey?: number | null;
  /** @format int32 */
  stateRank?: number | null;
}

export interface PersonSportRankingResponseRelatedRankingResponseRelatedPagedData {
  list?: PersonSportRankingResponse[];
  pagination?: Pagination;
  relatedModel?: RelatedRankingResponse;
}

export interface PlayerRatingAllResponse {
  change?: Record<string, PlayerRatingChange>;
  /** @format int32 */
  classYear?: number | null;
  fiveStarPlus: boolean;
  link?: string | null;
  nearlyFiveStarPlus: boolean;
  /** @format int32 */
  overallRank?: number | null;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format int32 */
  rankingKey?: number | null;
  rankingType: RankingType;
  /** @format int32 */
  rankingYear: number;
  /** @format double */
  rating?: number | null;
  sport?: string | null;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
  type?: string;
}

export enum PlayerRatingChange {
  Decrease = 'Decrease',
  None = 'None',
  Increase = 'Increase',
}

export interface PlayerResponse {
  /** @format int32 */
  age?: number | null;
  athleteVerified: boolean;
  classRank?: string | null;
  /** @format int32 */
  classYear?: number | null;
  /** @format date-time */
  dateOfBirth?: string | null;
  defaultAsset?: AssetResponse;
  defaultSport?: AttributeResponse;
  division?: string | null;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  firstName?: string;
  fullName?: string;
  height?: string | null;
  highSchool?: OrganizationResponse;
  highSchoolName?: string | null;
  hometown?: AttributeResponse;
  /** @format int32 */
  jerseyNumber?: number | null;
  /** @format int64 */
  key: number;
  lastName?: string;
  position?: AttributeResponse;
  prospectVerified: boolean;
  /** @format int64 */
  recruitmentKey?: number | null;
  slug?: string;
  state?: AttributeResponse;
  /** @format double */
  weight?: number | null;
}

export interface PlayerServiceRatingCountResponse {
  on3: boolean;
  /** @format int32 */
  count: number;
  espn: boolean;
  rivals: boolean;
  tfs: boolean;
}

export interface PositionResponse {
  abbreviation?: string | null;
  /** @format int32 */
  key: number;
  name?: string;
  positionType: PositionType;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey?: number | null;
}

export enum PositionType {
  Player = 'Player',
  Coach = 'Coach',
  Operations = 'Operations',
}

export enum PsrIndexOrderBy {
  Consensus = 'Consensus',
  On3 = 'On3',
}

export interface PsrdAdminManageResponse {
  /** @format int64 */
  key: number;
  /** @format int32 */
  order?: number | null;
  person?: SlimPersonResponse;
  positionAbbreviation?: string | null;
  /** @format int32 */
  positionKey?: number | null;
  /** @format int32 */
  rankingKey: number;
  /** @format double */
  rating?: number | null;
  /** @format int32 */
  stars?: number | null;
  stateAbbreviation?: string | null;
  /** @format int32 */
  stateKey?: number | null;
}

export interface PsrdAdminManageResponsePagedData {
  list?: PsrdAdminManageResponse[];
  pagination?: Pagination;
}

export interface PsrdAdminManageSearchResponse {
  /** @format int64 */
  key?: number | null;
  /** @format int32 */
  order?: number | null;
  person?: SlimPersonResponse;
  /** @format int64 */
  personSportOrganizationKey?: number | null;
  positionAbbreviation?: string | null;
  /** @format int32 */
  positionKey?: number | null;
  /** @format int32 */
  rankingKey?: number | null;
  /** @format double */
  rating?: number | null;
  /** @format int64 */
  recruitmentKey?: number | null;
  /** @format int32 */
  stars?: number | null;
  stateAbbreviation?: string | null;
  /** @format int32 */
  stateKey?: number | null;
}

export interface PsrdAdminManageSearchResponsePagedData {
  list?: PsrdAdminManageSearchResponse[];
  pagination?: Pagination;
}

export interface PsrdAdminPreviewManageResponse {
  /** @format int32 */
  change?: number | null;
  /** @format int32 */
  consensusChange?: number | null;
  /** @format int32 */
  consensusOverallRank?: number | null;
  /** @format int32 */
  consensusPositionRank?: number | null;
  /** @format double */
  consensusRating?: number | null;
  /** @format int32 */
  consensusStars?: number | null;
  /** @format int32 */
  consensusStateRank?: number | null;
  /** @format int64 */
  key: number;
  nilBreakdown?: NilDashboardValuationBreakdownResponse;
  organization?: OrganizationResponse;
  /** @format int32 */
  overallRank?: number | null;
  person?: SlimPersonResponse;
  position?: PositionResponse;
  /** @format double */
  positionBoost?: number | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format int32 */
  publishedConsensusOverallRank?: number | null;
  /** @format int32 */
  publishedOverallRank?: number | null;
  /** @format int32 */
  rankingKey: number;
  /** @format double */
  rating?: number | null;
  /** @format int32 */
  stars?: number | null;
  /** @format int32 */
  stateRank?: number | null;
}

export interface PsrdAdminPreviewManageResponseRankingResponseRelatedPagedData {
  list?: PsrdAdminPreviewManageResponse[];
  pagination?: Pagination;
  relatedModel?: RankingResponse;
}

export interface PsrdAdminResponse {
  /** @format int32 */
  espnOverallRank?: number | null;
  /** @format int32 */
  espnPositionRank?: number | null;
  /** @format double */
  espnRating?: number | null;
  /** @format int32 */
  espnStars?: number | null;
  /** @format int32 */
  espnStateRank?: number | null;
  /** @format int64 */
  key: number;
  organizationName?: string | null;
  person?: SlimPersonResponse;
  /** @format int64 */
  personSportOrganizationKey?: number | null;
  ranking?: RankingResponse;
  /** @format int32 */
  rankingKey: number;
  rankingType: RankingType;
  /** @format double */
  rating?: number | null;
  /** @format int64 */
  recruitmentKey?: number | null;
  /** @format int32 */
  rivalsOverallRank?: number | null;
  /** @format int32 */
  rivalsPositionRank?: number | null;
  /** @format double */
  rivalsRating?: number | null;
  /** @format int32 */
  rivalsStars?: number | null;
  /** @format int32 */
  rivalsStateRank?: number | null;
  /** @format int32 */
  tfsSportsOverallRank?: number | null;
  /** @format int32 */
  tfsSportsPositionRank?: number | null;
  /** @format double */
  tfsSportsRating?: number | null;
  /** @format int32 */
  tfsSportsStateRank?: number | null;
}

export interface PsrdAdminResponsePagedData {
  list?: PsrdAdminResponse[];
  pagination?: Pagination;
}

export interface RankingResponse {
  /** @format double */
  on3Percent?: number | null;
  autoPublish: boolean;
  /** @format int32 */
  averageCommits: number;
  createVersion: boolean;
  /** @format int32 */
  dateCreated: number;
  /** @format int32 */
  dateScheduled?: number | null;
  /** @format int32 */
  dateUpdated?: number | null;
  /** @format int32 */
  draftRankStrength: number;
  /** @format double */
  espnPercent?: number | null;
  isLegacy: boolean;
  isLocked: boolean;
  /** @format int32 */
  key: number;
  organizationType: OrganizationType;
  /** @format int32 */
  rankStrength: number;
  /** @format double */
  rivalsPercent?: number | null;
  sport?: SportBase;
  /** @format int32 */
  sportKey: number;
  staleness: RankingStaleness;
  status: RankingStatus;
  /** @format double */
  tfsSportsPercent?: number | null;
  type: RankingType;
  /** @format int32 */
  year: number;
}

export interface RankingResponsePagedData {
  list?: RankingResponse[];
  pagination?: Pagination;
}

export enum RankingStaleness {
  None = 'None',
  On300 = 'On300',
  Consensus = 'Consensus',
}

export enum RankingStatus {
  Draft = 'Draft',
  Processing = 'Processing',
  Ready = 'Ready',
  Scheduled = 'Scheduled',
  Publishing = 'Publishing',
  Published = 'Published',
}

export enum RankingType {
  Player = 'Player',
  AllTimePlayer = 'AllTimePlayer',
  TransferPortal = 'TransferPortal',
  AllTimeTransferPortal = 'AllTimeTransferPortal',
  Draft = 'Draft',
  AllTimeDraft = 'AllTimeDraft',
  Organization = 'Organization',
  AllTimeOrganization = 'AllTimeOrganization',
  Roster = 'Roster',
  AllTimeRoster = 'AllTimeRoster',
  TeamTransferPortal = 'TeamTransferPortal',
  AllTimeTeamTransferPortal = 'AllTimeTeamTransferPortal',
  TeamComposite = 'TeamComposite',
  AllTimeTeamComposite = 'AllTimeTeamComposite',
  ProjectedImpact = 'ProjectedImpact',
}

export interface RatingExtendedResponse {
  /** @format int32 */
  consensusNationalRank?: number | null;
  /** @format int32 */
  consensusPositionRank?: number | null;
  /** @format double */
  consensusRating?: number | null;
  /** @format int32 */
  consensusStars?: number | null;
  /** @format int32 */
  consensusStateRank?: number | null;
  fiveStarPlus: boolean;
  /** @format int64 */
  key: number;
  /** @format int32 */
  nationalRank?: number | null;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format double */
  rating?: number | null;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
}

export interface RatingResponse {
  /** @format int32 */
  consensusNationalRank?: number | null;
  /** @format int32 */
  consensusPositionRank?: number | null;
  /** @format double */
  consensusRating?: number | null;
  /** @format int32 */
  consensusStars?: number | null;
  /** @format int32 */
  consensusStateRank?: number | null;
  fiveStarPlus: boolean;
  /** @format int64 */
  key: number;
  /** @format int32 */
  nationalRank?: number | null;
  nearlyFiveStarPlus: boolean;
  positionAbbr?: string | null;
  /** @format int32 */
  positionRank?: number | null;
  /** @format double */
  rating?: number | null;
  sport?: AttributeResponse;
  /** @format int32 */
  stars?: number | null;
  stateAbbr?: string | null;
  /** @format int32 */
  stateRank?: number | null;
  /** @format int32 */
  year?: number | null;
}

export interface RdbV1PersonSportRankingsListParams {
  /**
   * Consensus and On3 Ratings are now sent in every response. Manually set to false or it will send Consensus twice.
   * @deprecated
   */
  consensus?: boolean;
  /** @format int32 */
  consensusStars?: number;
  fiveStars?: boolean;
  /**
   * @format int32
   * @default 50
   */
  limit?: number;
  nearlyFiveStars?: boolean;
  orderBy?: PsrIndexOrderBy;
  orgType?: OrganizationType;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  positionAbbr?: string;
  rankingType?: RankingType;
  /** @format int32 */
  sportKey: number;
  stateAbbr?: string;
  /** @format int32 */
  year?: number;
}

export interface RdbV1PersonSportRankingsNearlyFiveStarPlusListParams {
  /**
   * @format int32
   * @default 25
   */
  limit?: number;
  orgType?: OrganizationType;
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  positionAbbr?: string;
  rankingType?: RankingType;
  sortByIndustry?: SortByIndustry;
  /** @format int32 */
  sportKey: number;
  stateAbbr?: string;
  /** @format int32 */
  year?: number;
}

export interface RdbV1TeamTransferPortalRankingsListParams {
  /** The abbreviation of the conference */
  confAbbr?: string;
  /**
   * The number of items to return
   * @format int32
   */
  items?: number;
  /**
   * Page of the ranking, defaults to 1.
   * Sending 0 will return the whole list
   * @format int32
   */
  page?: number;
  /** The field to sort on. Default is score */
  sort?: TtprRankingSort;
  /** Direction of sort. Default is descending */
  sortDir?: SortDirection;
  /**
   * Ranking's sport key, will default to Football
   * @format int32
   */
  sportKey?: number;
  /**
   * Ranking's year, will default to the current recruiting season for the sport
   * @format int32
   */
  year?: number;
}

export interface RecStatusResponse {
  committedAsset?: OrganizationResponse;
  /** @format date-time */
  date?: string | null;
  decommittedAsset?: OrganizationResponse;
  draft: boolean;
  /** @format int32 */
  recruitmentYear?: number | null;
  shortTermSignee: boolean;
  transfer: boolean;
  /** @format date-time */
  transferEntered?: string | null;
  /** @format date-time */
  transferWithdrawn?: string | null;
  transferredAsset?: OrganizationResponse;
  type?: string;
}

export interface RecruitmentStatusResponse {
  /** @format date-time */
  commitmentDate?: string | null;
  committedOrganizationAsset?: ImageResponse;
  committedOrganizationAssetUrl?: string | null;
  /** @format int64 */
  committedOrganizationKey?: number | null;
  committedOrganizationPrimaryColor?: string | null;
  committedOrganizationSlug?: string | null;
  /** @format int32 */
  highestInterestLevel?: number | null;
  /** @format int32 */
  interestCount: number;
  isCommitted: boolean;
  isEnrolled: boolean;
  isSigned: boolean;
  isTransfer: boolean;
  /** @format int32 */
  recruitmentYear: number;
  shortTermSignee: boolean;
  sportName?: string | null;
  transferredFromOrganizationAssetUrl?: string | null;
  transferredFromOrganizationSlug?: string | null;
}

export interface RelatedRankingResponse {
  position?: PositionResponse;
  ranking?: RankingResponse;
  state?: StateResponse;
}

export interface RenOrgCompositeRankingDraftReq {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  calPrepsOverallRank?: number | null;
  /** @format double */
  calPrepsRating?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  calPrepsStateRank?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  espnOverallRank?: number | null;
  /** @format double */
  espnRating?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  espnStateRank?: number | null;
  /**
   * @format int64
   * @min 1
   */
  key?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  masseyOverallRank?: number | null;
  /** @format double */
  masseyRating?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  masseyStateRank?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxPrepsOverallRank?: number | null;
  /** @format double */
  maxPrepsRating?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxPrepsStateRank?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  organizationKey: number;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  rankingKey: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  siOverallRank?: number | null;
  /** @format double */
  siRating?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  siStateRank?: number | null;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  stateKey?: number | null;
}

export interface ResponseMessage {
  message?: string;
  /** @format int32 */
  statusCode: number;
}

export interface RpmPercentResponse {
  /** @format double */
  distance?: number | null;
  organization?: OrganizationResponse;
  /** @format double */
  percent?: number | null;
  showRpmPercentage?: boolean | null;
}

export interface RpmResponse {
  committed: boolean;
  /** @format date-time */
  committedDate?: string | null;
  /** @format double */
  distance?: number | null;
  flippedFromOrganization?: Team;
  /** @format int64 */
  key: number;
  organization?: Team;
  /** @format double */
  percent?: number | null;
  status?: string | null;
  /** @format date-time */
  statusDate?: string | null;
}

export interface ScheduleRankingRequest {
  /** @format date-time */
  scheduledDate: string;
}

export interface SimpleOrgRankingResponse {
  organization?: OrganizationBase;
  /** @format int32 */
  overallRank?: number | null;
  /** @format double */
  points: number;
  /** @format int64 */
  rankingKey: number;
}

export interface SlimPersonResponse {
  /** @format int32 */
  age?: number | null;
  athleteVerified: boolean;
  classRank?: string;
  /**
   * The person's high school class year
   * @format int32
   */
  classYear?: number | null;
  defaultAsset?: ImageResponse;
  /** The url of the person's default asset */
  defaultAssetUrl?: string | null;
  /** Did the person enroll early */
  earlyEnrollee: boolean;
  /** Did the person sign early */
  earlySignee: boolean;
  /** The person's height in Feet and Inches */
  formattedHeight?: string | null;
  /**
   * The person's height in inches
   * @format double
   */
  height?: number | null;
  highSchool?: OrganizationResponse;
  /** The name of the High School the person attended */
  highSchoolName?: string | null;
  /** The name of the person's hometown */
  homeTownName?: string | null;
  /** @format int64 */
  key: number;
  /** The full name of the person */
  name?: string;
  /** The abbreviation of the person's primary position */
  positionAbbreviation?: string | null;
  prospectVerified: boolean;
  /** @format int64 */
  recruitmentKey?: number | null;
  /** The slug of the person */
  slug?: string;
  /**
   * The person's weight in pounds
   * @format double
   */
  weight?: number | null;
}

export enum SortByIndustry {
  On3Consensus = 'On3Consensus',
  On3 = 'On3',
  TwentyFourSevenSports = 'TwentyFourSevenSports',
  Espn = 'Espn',
  Rivals = 'Rivals',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SportBase {
  /** @format int32 */
  key: number;
  name?: string;
}

export interface SportResponseRecord {
  abbreviation?: string;
  isIndustryRankable: boolean;
  isRankable: boolean;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface StateResponse {
  abbreviation?: string;
  /** @format int32 */
  countryKey?: number | null;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface StringListResourceResponseMessage {
  message?: string;
  resource?: string[];
  /** @format int32 */
  statusCode: number;
}

export interface SyncRankingPhaseReq {
  organizationType: OrganizationType;
  /** @format date-time */
  phaseDate: string;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  sportKey: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  year: number;
}

export interface Team {
  abbreviation?: string | null;
  asset?: AssetResponse;
  assetUrl?: KeyUrlBase;
  fullName?: string;
  /** @format int32 */
  key: number;
  mascot?: string | null;
  name?: string;
  primaryColor?: string | null;
  slug?: string | null;
}

export interface TeamTransferPortalRankingResponse {
  /** @format double */
  adjustedNilValuation?: number | null;
  /** @format date */
  date: string;
  /** @format int32 */
  dateModified: number;
  /** @format int32 */
  fiveStarsIn?: number | null;
  /** @format int32 */
  fiveStarsNet?: number | null;
  /** @format int32 */
  fiveStarsOut?: number | null;
  /** @format int32 */
  fourStarsIn?: number | null;
  /** @format int32 */
  fourStarsNet?: number | null;
  /** @format int32 */
  fourStarsOut?: number | null;
  /** @format int64 */
  key: number;
  /** @format double */
  nilValuationChange?: number | null;
  organization?: OrganizationResponse;
  /** @format int32 */
  organizationKey: number;
  /** @format double */
  originalNilValuation?: number | null;
  /** @format int32 */
  overallRank?: number | null;
  /** @format double */
  overallScore?: number | null;
  /** @format int32 */
  rankingKey: number;
  /** @format double */
  rawScore?: number | null;
  /** @format double */
  rawScoreIn?: number | null;
  /** @format double */
  rawScoreOut?: number | null;
  /** @format int32 */
  threeStarsIn?: number | null;
  /** @format int32 */
  threeStarsNet?: number | null;
  /** @format int32 */
  threeStarsOut?: number | null;
  /** @format int32 */
  totalIn?: number | null;
  /** @format double */
  totalInAverageRating?: number | null;
  /** @format int32 */
  totalOut?: number | null;
  /** @format double */
  totalOutAverageRating?: number | null;
}

export interface TeamTransferPortalRankingResponseRankingResponseRelatedPagedData {
  list?: TeamTransferPortalRankingResponse[];
  pagination?: Pagination;
  relatedModel?: RankingResponse;
}

export enum TtprRankingSort {
  Score = 'Score',
  In = 'In',
  Out = 'Out',
}

export interface UpdatePsrdOrderRequest {
  /** @format int64 */
  key?: number | null;
  /** @format int32 */
  order?: number | null;
  /** @format int64 */
  personSportOrganizationKey?: number | null;
  rankingType?: RankingType;
  /** @format double */
  rating?: number | null;
  /** @format int64 */
  recruitmentKey?: number | null;
}

export interface UpdatePsrdRequest {
  /** @format int32 */
  espnOverallRank?: number | null;
  /** @format int32 */
  espnPositionRank?: number | null;
  /** @format double */
  espnRating?: number | null;
  /** @format int32 */
  espnStars?: number | null;
  /** @format int32 */
  espnStateRank?: number | null;
  /** @format int64 */
  key?: number | null;
  /** @format int64 */
  personSportOrganizationKey?: number | null;
  rankingType?: RankingType;
  /** @format double */
  rating?: number | null;
  /** @format int64 */
  recruitmentKey?: number | null;
  /** @format int32 */
  rivalsOverallRank?: number | null;
  /** @format int32 */
  rivalsPositionRank?: number | null;
  /** @format double */
  rivalsRating?: number | null;
  /** @format int32 */
  rivalsStars?: number | null;
  /** @format int32 */
  rivalsStateRank?: number | null;
  /** @format int32 */
  tfsSportsOverallRank?: number | null;
  /** @format int32 */
  tfsSportsPositionRank?: number | null;
  /** @format double */
  tfsSportsRating?: number | null;
  /** @format int32 */
  tfsSportsStateRank?: number | null;
}

export interface V1OrganizationCompositeRankingsListParams {
  /**
   * Number of items per page. Defaults to 25.
   * Allowed are 5, 10, 25, 50, 100
   * @format int32
   */
  items?: number;
  /** The org type to filter on. Defaults to high school */
  orgType?: OrganizationType;
  /**
   * Page of composite rankings to return. Defaults to the first page
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** The source to filter/sort on. Defaults to composite if not sent */
  source?: MappingSource;
  /**
   * The sport to filter on
   * @format int32
   * @min 1
   * @max 4294967295
   */
  sportKey: number;
  /**
   * The state to filter rankings on
   * @format int32
   * @min 1
   * @max 4294967295
   */
  stateKey?: number;
  /**
   * The year to filter on. Defaults to the current year
   * @format int32
   * @min 1
   * @max 2147483647
   */
  year?: number;
}

export interface V1OrganizationRankingsSimpleListParams {
  additionalPersonSportKeys?: number[];
  /** @format int32 */
  includedOrganizationKey?: number;
  /** @format int32 */
  sportKey?: number;
  /** @format int32 */
  year?: number;
}

export interface V1PersonSportRankingDraftsListParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
}

export interface V1PersonSportRankingDraftsRenovateOrderUpdateParams {
  /**
   * return the resourceResponseMessage (true) or pagedData (false)
   * @default true
   */
  legacy?: boolean;
}

export type V1PersonSportRankingDraftsRenovateOrderUpdatePayload =
  UpdatePsrdOrderRequest[];

export interface V1PersonSportRankingDraftsRenovateUpdateParams {
  /**
   * return the resourceResponseMessage (true) or pagedData (false)
   * @default true
   */
  legacy?: boolean;
}

export type V1PersonSportRankingDraftsRenovateUpdatePayload =
  UpdatePsrdRequest[];

export interface V1RankingsListParams {
  /** The On3.DataAccess.Enums.OrganizationType to filter to */
  orgType?: OrganizationType;
  /**
   * The page to return
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The sport to filter to
   * @format int32
   */
  sportKey?: number;
  /** The On3.DataAccess.Enums.RankingType to filter to */
  type?: RankingType;
  /**
   * The year to filter to
   * @format int32
   */
  year?: number;
}

export interface V1RankingsOrganizationCompositeRankingDraftsDetailParams {
  /** @format int32 */
  items?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  page?: number;
  /** @format int32 */
  rankingKey: number;
  source?: MappingSource;
  /**
   * @format int32
   * @min 1
   * @max 4294967295
   */
  stateKey?: number;
}

export interface V1RankingsOrganizationRankingDraftsAdminPreviewDetailParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int32 */
  rankingKey: number;
}

export interface V1RankingsPersonSportRankingDraftsAdminDetailParams {
  /**
   * @format int32
   * @default 1
   */
  page?: number;
  /** @format int32 */
  rankingKey: number;
}

export interface V1RankingsPersonSportRankingDraftsAdminPreviewDetailParams {
  /**
   * The page of PSRDs to show
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The key of the Position to filter
   * @format int32
   */
  positionKey?: number;
  /**
   * The key of the Ranking
   * @format int32
   */
  rankingKey: number;
  /**
   * The key of the State to filter
   * @format int32
   */
  stateKey?: number;
}

export interface V1RankingsPersonSportRankingDraftsSearchAdminDetailParams {
  /**
   * The page of PSRDs to return
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The key of the Ranking to filter on
   * @format int32
   */
  rankingKey: number;
  /** The term of the Person's full name to search */
  term?: string;
}

export interface V1RankingsRankedPersonSportRankingDraftsAdminDetailParams {
  /**
   * The page of PSRDs to return
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The key of the Ranking to filter on
   * @format int32
   */
  rankingKey: number;
}

export interface V1RankingsUnrankedPersonSportRankingDraftsAdminDetailParams {
  /**
   * The page of PSRDs to return
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The key of the Ranking to filter on
   * @format int32
   */
  rankingKey: number;
}

export interface V1RecruitmentsPersonSportRankingDraftsAdminDetailParams {
  /**
   * to return a list (true) or paged data (false)
   * @default true
   */
  legacy?: boolean;
  /**
   * recruitment key to filter on
   * @format int64
   */
  recruitmentKey: number;
}

export type QueryParamsType = Record<string | number, any>;

export type RequestParams = Omit<
  AxiosRequestConfig,
  'data' | 'method' | 'params' | 'url' | 'responseType'
>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/**
 * No description
 *
 * @tags OrganizationCompositeRankingDraft
 * @name V1HierarchyRankingPhasesSyncRanksToOcrdsCreate
 * @summary Dev route to test syncing the composite ranks from a phase
 * @request POST:/rdb/v1/hierarchy-ranking-phases/sync-ranks-to-ocrds
 * @secure
 */
export const v1HierarchyRankingPhasesSyncRanksToOcrdsCreate = (
  client: AxiosInstance,
  data: SyncRankingPhaseReq,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/hierarchy-ranking-phases/sync-ranks-to-ocrds`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationCompositeRankingDraft
 * @name V1OrganizationCompositeRankingDraftsDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request DELETE:/rdb/v1/organization-composite-ranking-drafts/{key}
 * @secure
 */
export const v1OrganizationCompositeRankingDraftsDelete = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/organization-composite-ranking-drafts/${key}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationCompositeRankingDraft
 * @name V1OrganizationCompositeRankingDraftsImportRanksCreate
 * @summary  (Roles: developer)
 * @request POST:/rdb/v1/organization-composite-ranking-drafts/import-ranks
 * @secure
 */
export const v1OrganizationCompositeRankingDraftsImportRanksCreate = (
  client: AxiosInstance,
  data: ImportOcrdRanksReq,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/organization-composite-ranking-drafts/import-ranks`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationCompositeRankingDraft
 * @name V1OrganizationCompositeRankingDraftsRenovateUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request PUT:/rdb/v1/organization-composite-ranking-drafts/renovate
 * @secure
 */
export const v1OrganizationCompositeRankingDraftsRenovateUpdate = (
  client: AxiosInstance,
  data: RenOrgCompositeRankingDraftReq,
  params: RequestParams = {},
) =>
  client
    .request<OrgCompositeRankingDraftRes>({
      url: `/rdb/v1/organization-composite-ranking-drafts/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationCompositeRanking
 * @name V1OrganizationCompositeRankingsList
 * @summary Gets a page of composite rankings for the given filters
if the ranking exists and are published
 * @request GET:/rdb/v1/organization-composite-rankings
 */
export const v1OrganizationCompositeRankingsList = (
  client: AxiosInstance,
  query: V1OrganizationCompositeRankingsListParams,
  params: RequestParams = {},
) =>
  client
    .request<OrgCompositeRankingResRelatedRankingResponseRelatedPagedData>({
      url: `/rdb/v1/organization-composite-rankings`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationRanking
 * @name V1OrganizationRankingsDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2)
 * @request GET:/rdb/v1/organization-rankings/{key}
 * @secure
 */
export const v1OrganizationRankingsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<OrganizationRankingResponse>({
      url: `/rdb/v1/organization-rankings/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationRanking
 * @name V1OrganizationRankingsSimpleList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2)
 * @request GET:/rdb/v1/organization-rankings/simple
 * @secure
 */
export const v1OrganizationRankingsSimpleList = (
  client: AxiosInstance,
  query: V1OrganizationRankingsSimpleListParams,
  params: RequestParams = {},
) =>
  client
    .request<SimpleOrgRankingResponse[]>({
      url: `/rdb/v1/organization-rankings/simple`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportRankingDraft
 * @name V1PersonSportRankingDraftsDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2)
 * @request GET:/rdb/v1/person-sport-ranking-drafts/{key}
 * @secure
 */
export const v1PersonSportRankingDraftsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<PersonSportRankingDraftResponse>({
      url: `/rdb/v1/person-sport-ranking-drafts/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportRankingDraft
 * @name V1PersonSportRankingDraftsList
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2)
 * @request GET:/rdb/v1/person-sport-ranking-drafts
 * @secure
 */
export const v1PersonSportRankingDraftsList = (
  client: AxiosInstance,
  query: V1PersonSportRankingDraftsListParams,
  params: RequestParams = {},
) =>
  client
    .request<PersonSportRankingDraftResponsePagedData>({
      url: `/rdb/v1/person-sport-ranking-drafts`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportRankingDraft
 * @name V1PersonSportRankingDraftsRenovateOrderUpdate
 * @summary Renovates a list of PSRDs from the admin ranking management tool.
Specifically around the Order and Rating
 * @request PUT:/rdb/v1/person-sport-ranking-drafts/renovate-order
 * @secure
 */
export const v1PersonSportRankingDraftsRenovateOrderUpdate = (
  client: AxiosInstance,
  query: V1PersonSportRankingDraftsRenovateOrderUpdateParams,
  data: V1PersonSportRankingDraftsRenovateOrderUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<PsrdAdminResponsePagedData>({
      url: `/rdb/v1/person-sport-ranking-drafts/renovate-order`,
      method: 'PUT',
      params: query,
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportRankingDraft
 * @name V1PersonSportRankingDraftsRenovateUpdate
 * @summary Renovates a list of PSRDs from the admin player profile.
Primarily used to add/update the ratings and ranks from the other services
 * @request PUT:/rdb/v1/person-sport-ranking-drafts/renovate
 * @secure
 */
export const v1PersonSportRankingDraftsRenovateUpdate = (
  client: AxiosInstance,
  query: V1PersonSportRankingDraftsRenovateUpdateParams,
  data: V1PersonSportRankingDraftsRenovateUpdatePayload,
  params: RequestParams = {},
) =>
  client
    .request<PsrdAdminResponsePagedData>({
      url: `/rdb/v1/person-sport-ranking-drafts/renovate`,
      method: 'PUT',
      params: query,
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Ranking
 * @name V1RankingsDetail
 * @summary Gets a Ranking by key
 * @request GET:/rdb/v1/rankings/{key}
 * @secure
 */
export const v1RankingsDetail = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<RankingResponse>({
      url: `/rdb/v1/rankings/${key}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Ranking
 * @name V1RankingsList
 * @summary Get a paginated list of rankings
 * @request GET:/rdb/v1/rankings
 * @secure
 */
export const v1RankingsList = (
  client: AxiosInstance,
  query: V1RankingsListParams,
  params: RequestParams = {},
) =>
  client
    .request<RankingResponsePagedData>({
      url: `/rdb/v1/rankings`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationCompositeRankingDraft
 * @name V1RankingsOrganizationCompositeRankingDraftsDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request GET:/rdb/v1/rankings/{rankingKey}/organization-composite-ranking-drafts
 * @secure
 */
export const v1RankingsOrganizationCompositeRankingDraftsDetail = (
  client: AxiosInstance,
  {
    rankingKey,
    ...query
  }: V1RankingsOrganizationCompositeRankingDraftsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<OrgCompositeRankingDraftResRelatedRankingResponseRelatedPagedData>(
      {
        url: `/rdb/v1/rankings/${rankingKey}/organization-composite-ranking-drafts`,
        method: 'GET',
        params: query,
        ...params,
      },
    )
    .then((r) => r.data);

/**
 * No description
 *
 * @tags OrganizationRankingDraft
 * @name V1RankingsOrganizationRankingDraftsAdminPreviewDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request GET:/rdb/v1/rankings/{rankingKey}/organization-ranking-drafts/admin-preview
 * @secure
 */
export const v1RankingsOrganizationRankingDraftsAdminPreviewDetail = (
  client: AxiosInstance,
  {
    rankingKey,
    ...query
  }: V1RankingsOrganizationRankingDraftsAdminPreviewDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<OrdAdminPreviewManageResponsePagedData>({
      url: `/rdb/v1/rankings/${rankingKey}/organization-ranking-drafts/admin-preview`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportRankingDraft
 * @name V1RankingsPersonSportRankingDraftsAdminDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2)
 * @request GET:/rdb/v1/rankings/{rankingKey}/person-sport-ranking-drafts/admin
 * @secure
 */
export const v1RankingsPersonSportRankingDraftsAdminDetail = (
  client: AxiosInstance,
  { rankingKey, ...query }: V1RankingsPersonSportRankingDraftsAdminDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PsrdAdminResponsePagedData>({
      url: `/rdb/v1/rankings/${rankingKey}/person-sport-ranking-drafts/admin`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportRankingDraft
 * @name V1RankingsPersonSportRankingDraftsAdminPreviewDetail
 * @summary Gets a preview of the On300 or by Position or by State
compared to the published versions
 * @request GET:/rdb/v1/rankings/{rankingKey}/person-sport-ranking-drafts/admin-preview
 * @secure
 */
export const v1RankingsPersonSportRankingDraftsAdminPreviewDetail = (
  client: AxiosInstance,
  {
    rankingKey,
    ...query
  }: V1RankingsPersonSportRankingDraftsAdminPreviewDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PsrdAdminPreviewManageResponseRankingResponseRelatedPagedData>({
      url: `/rdb/v1/rankings/${rankingKey}/person-sport-ranking-drafts/admin-preview`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportRankingDraft
 * @name V1RankingsPersonSportRankingDraftsAlignByRecruitmentCreate
 * @summary Checks each PSRD in the Ranking to determine if it needs to be moved
to a different Ranking. Primary use case is a Person reclassifies
 * @request POST:/rdb/v1/rankings/{rankingKey}/person-sport-ranking-drafts/align-by-recruitment
 * @secure
 */
export const v1RankingsPersonSportRankingDraftsAlignByRecruitmentCreate = (
  client: AxiosInstance,
  rankingKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/rankings/${rankingKey}/person-sport-ranking-drafts/align-by-recruitment`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportRankingDraft
 * @name V1RankingsPersonSportRankingDraftsClearDelete
 * @summary  (Roles: developer, rdb5, rdb4, rdb3)
 * @request DELETE:/rdb/v1/rankings/{rankingKey}/person-sport-ranking-drafts/clear
 * @secure
 */
export const v1RankingsPersonSportRankingDraftsClearDelete = (
  client: AxiosInstance,
  rankingKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/rankings/${rankingKey}/person-sport-ranking-drafts/clear`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportRankingDraft
 * @name V1RankingsPersonSportRankingDraftsNormalizeCreate
 * @summary Normalizes all of the ratings from the services for each PSRD in the Ranking
 * @request POST:/rdb/v1/rankings/{rankingKey}/person-sport-ranking-drafts/normalize
 * @secure
 */
export const v1RankingsPersonSportRankingDraftsNormalizeCreate = (
  client: AxiosInstance,
  rankingKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/rankings/${rankingKey}/person-sport-ranking-drafts/normalize`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportRankingDraft
 * @name V1RankingsPersonSportRankingDraftsReorderByRatingCreate
 * @summary Resets the order by from 1 to N based on the existing order and rating
 * @request POST:/rdb/v1/rankings/{rankingKey}/person-sport-ranking-drafts/reorder-by-rating
 * @secure
 */
export const v1RankingsPersonSportRankingDraftsReorderByRatingCreate = (
  client: AxiosInstance,
  rankingKey: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/rankings/${rankingKey}/person-sport-ranking-drafts/reorder-by-rating`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportRankingDraft
 * @name V1RankingsPersonSportRankingDraftsSearchAdminDetail
 * @summary Returns a list of potential people based on their Recruitment year
for a Player ranking or by PersonSportOrganization year for a
Roster ranking
 * @request GET:/rdb/v1/rankings/{rankingKey}/person-sport-ranking-drafts/search-admin
 * @secure
 */
export const v1RankingsPersonSportRankingDraftsSearchAdminDetail = (
  client: AxiosInstance,
  {
    rankingKey,
    ...query
  }: V1RankingsPersonSportRankingDraftsSearchAdminDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PsrdAdminManageSearchResponsePagedData>({
      url: `/rdb/v1/rankings/${rankingKey}/person-sport-ranking-drafts/search-admin`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Ranking
 * @name V1RankingsProcessCreate
 * @summary Processes the given Ranking
 * @request POST:/rdb/v1/rankings/{key}/process
 * @secure
 */
export const v1RankingsProcessCreate = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/rankings/${key}/process`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Ranking
 * @name V1RankingsPublishCreate
 * @summary Publishes the given Ranking
 * @request POST:/rdb/v1/rankings/{key}/publish
 * @secure
 */
export const v1RankingsPublishCreate = (
  client: AxiosInstance,
  key: number,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/rankings/${key}/publish`,
      method: 'POST',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportRankingDraft
 * @name V1RankingsRankedPersonSportRankingDraftsAdminDetail
 * @summary Get the PSRDs that have an Order set
 * @request GET:/rdb/v1/rankings/{rankingKey}/ranked-person-sport-ranking-drafts/admin
 * @secure
 */
export const v1RankingsRankedPersonSportRankingDraftsAdminDetail = (
  client: AxiosInstance,
  {
    rankingKey,
    ...query
  }: V1RankingsRankedPersonSportRankingDraftsAdminDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PsrdAdminManageResponsePagedData>({
      url: `/rdb/v1/rankings/${rankingKey}/ranked-person-sport-ranking-drafts/admin`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Ranking
 * @name V1RankingsRenovatePercentsUpdate
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2)
 * @request PUT:/rdb/v1/rankings/{key}/renovate-percents
 * @secure
 */
export const v1RankingsRenovatePercentsUpdate = (
  client: AxiosInstance,
  key: string,
  data: ApiRenovateRankingPercentsRequest,
  params: RequestParams = {},
) =>
  client
    .request<RankingResponse>({
      url: `/rdb/v1/rankings/${key}/renovate-percents`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Ranking
 * @name V1RankingsScheduleCreate
 * @summary Schedules a Ranking to be published at a given time
 * @request POST:/rdb/v1/rankings/{key}/schedule
 * @secure
 */
export const v1RankingsScheduleCreate = (
  client: AxiosInstance,
  key: number,
  data: ScheduleRankingRequest,
  params: RequestParams = {},
) =>
  client
    .request<ResponseMessage>({
      url: `/rdb/v1/rankings/${key}/schedule`,
      method: 'POST',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TeamTransferPortalRankingDraft
 * @name V1RankingsTeamTransferPortalRankingDraftsDetail
 * @summary  (Roles: developer, rdb5, rdb4, rdb3, rdb2)
 * @request GET:/rdb/v1/rankings/{rankingKey}/team-transfer-portal-ranking-drafts
 * @secure
 */
export const v1RankingsTeamTransferPortalRankingDraftsDetail = (
  client: AxiosInstance,
  rankingKey: number,
  params: RequestParams = {},
) =>
  client
    .request<TeamTransferPortalRankingResponseRankingResponseRelatedPagedData>({
      url: `/rdb/v1/rankings/${rankingKey}/team-transfer-portal-ranking-drafts`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportRankingDraft
 * @name V1RankingsUnrankedPersonSportRankingDraftsAdminDetail
 * @summary Gets the PSRDs that do no have an Order set
 * @request GET:/rdb/v1/rankings/{rankingKey}/unranked-person-sport-ranking-drafts/admin
 * @secure
 */
export const v1RankingsUnrankedPersonSportRankingDraftsAdminDetail = (
  client: AxiosInstance,
  {
    rankingKey,
    ...query
  }: V1RankingsUnrankedPersonSportRankingDraftsAdminDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PsrdAdminManageResponsePagedData>({
      url: `/rdb/v1/rankings/${rankingKey}/unranked-person-sport-ranking-drafts/admin`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags Ranking
 * @name V1RankingsUpdate
 * @summary Create/update a Ranking from the request
 * @request PUT:/rdb/v1/rankings
 * @secure
 */
export const v1RankingsUpdate = (
  client: AxiosInstance,
  data: CreateRankingRequest,
  params: RequestParams = {},
) =>
  client
    .request<RankingResponse>({
      url: `/rdb/v1/rankings`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportRankingDraft
 * @name V1RecruitmentsPersonSportRankingDraftsAdminDetail
 * @summary Gets the admin version of the PSRDs for a recruitment key
 * @request GET:/rdb/v1/recruitments/{recruitmentKey}/person-sport-ranking-drafts/admin
 * @secure
 */
export const v1RecruitmentsPersonSportRankingDraftsAdminDetail = (
  client: AxiosInstance,
  {
    recruitmentKey,
    ...query
  }: V1RecruitmentsPersonSportRankingDraftsAdminDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<PsrdAdminResponsePagedData>({
      url: `/rdb/v1/recruitments/${recruitmentKey}/person-sport-ranking-drafts/admin`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportRanking
 * @name RdbV1PersonSportRankingsList
 * @request GET:/public/rdb/v1/person-sport-rankings
 */
export const rdbV1PersonSportRankingsList = (
  client: AxiosInstance,
  query: RdbV1PersonSportRankingsListParams,
  params: RequestParams = {},
) =>
  client
    .request<PersonSportRankingResponseRelatedRankingResponseRelatedPagedData>({
      url: `/public/rdb/v1/person-sport-rankings`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags PersonSportRanking
 * @name RdbV1PersonSportRankingsNearlyFiveStarPlusList
 * @request GET:/public/rdb/v1/person-sport-rankings/nearly-five-star-plus
 */
export const rdbV1PersonSportRankingsNearlyFiveStarPlusList = (
  client: AxiosInstance,
  query: RdbV1PersonSportRankingsNearlyFiveStarPlusListParams,
  params: RequestParams = {},
) =>
  client
    .request<IndustryComparisonResponsePagedData>({
      url: `/public/rdb/v1/person-sport-rankings/nearly-five-star-plus`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags TeamTransferPortalRanking
 * @name RdbV1TeamTransferPortalRankingsList
 * @request GET:/public/rdb/v1/team-transfer-portal-rankings
 */
export const rdbV1TeamTransferPortalRankingsList = (
  client: AxiosInstance,
  query: RdbV1TeamTransferPortalRankingsListParams,
  params: RequestParams = {},
) =>
  client
    .request<TeamTransferPortalRankingResponseRankingResponseRelatedPagedData>({
      url: `/public/rdb/v1/team-transfer-portal-rankings`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);
