import type { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface AdminNilDealResponse {
  agent?: AgentResponse;
  /** @format double */
  amount?: number | null;
  /** @format int64 */
  collectiveGroupKey?: number | null;
  collectiveGroupName?: string | null;
  company?: NilCompanyResponse;
  /** @format date-time */
  date?: string | null;
  detailUrl?: string | null;
  /** @format int64 */
  key: number;
  organization?: OrganizationResponse;
  person?: PlayerResponse;
  positions?: PositionResponse[] | null;
  sourceUrl?: string | null;
  sports?: SportResponseRecord[] | null;
  type?: string;
  verified: boolean;
}

export interface AdminNilDealResponsePagedData {
  list?: AdminNilDealResponse[];
  pagination?: Pagination;
}

export interface AgencyRequest {
  /** @format int64 */
  key?: number | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  name: string;
}

export interface AgencyResponse {
  /** @format int64 */
  key: number;
  name?: string;
}

export interface AgencyResponsePagedData {
  list?: AgencyResponse[];
  pagination?: Pagination;
}

export interface AgentPersonResponse {
  agency?: AgencyResponse;
  /** @format int64 */
  key: number;
  name?: string;
}

export interface AgentPersonResponsePagedData {
  list?: AgentPersonResponse[];
  pagination?: Pagination;
}

export interface AgentRequestV2 {
  /** @format int64 */
  agencyKey?: number | null;
  /** @format int64 */
  key?: number | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  name: string;
}

export interface AgentResponse {
  company?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
}

/** Specifies the kind of asset and Asset object is. */
export enum AssetClasses {
  Unknown = 'Unknown',
  Image = 'Image',
  Video = 'Video',
}

export interface AssetResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export interface AttributeResponse {
  abbr?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
}

export enum Category {
  Undetermined = 'Undetermined',
  Highlights = 'Highlights',
  Training = 'Training',
  Experts = 'Experts',
  Conversations = 'Conversations',
  Camp = 'Camp',
}

export interface DeleteResponse {
  clientError: boolean;
  errors?: string[];
  success: boolean;
}

export interface FileResponse {
  key?: string;
  /** @format date-time */
  lastModifiedDate?: string | null;
  name?: string | null;
  /** @format int64 */
  personKey?: number | null;
  /** @format int64 */
  tag?: number | null;
}

export interface ImageResponse {
  altText?: string | null;
  /** Specifies the kind of asset and Asset object is. */
  assetType: AssetClasses;
  caption?: string | null;
  category?: Category;
  description?: string | null;
  domain?: string | null;
  domainOverride?: string | null;
  /** @format int32 */
  duration: number;
  fileSystem?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int64 */
  key: number;
  mimeType?: string | null;
  path?: string | null;
  source?: string | null;
  sourceOverride?: string | null;
  thumbnail?: string | null;
  title?: string | null;
  type?: string | null;
  /** @format int32 */
  width?: number | null;
}

export enum NILDealStatus {
  Submitted = 'Submitted',
  PendingInformation = 'PendingInformation',
  Approved = 'Approved',
  Denied = 'Denied',
}

export interface NilCompanyAdminResponse {
  defaultAsset?: ImageResponse;
  /** @format int64 */
  defaultAssetKey?: number | null;
  details?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
  url?: string | null;
}

export interface NilCompanyAdminResponsePagedData {
  list?: NilCompanyAdminResponse[];
  pagination?: Pagination;
}

export interface NilCompanyPartnerRequest {
  /** @format int64 */
  defaultAssetKey?: number | null;
  details?: string | null;
  /** @format int64 */
  key?: number | null;
  /** @minLength 1 */
  name: string;
  url?: string | null;
}

export interface NilCompanyResponse {
  assetUrl?: string | null;
  details?: string | null;
  /** @format int64 */
  key: number;
  name?: string | null;
  url?: string | null;
}

export interface NilDealSubmissionResponse {
  agentName?: string | null;
  /** @format double */
  amount?: number | null;
  collectiveGroupName?: string | null;
  companyName?: string | null;
  /** @format date-time */
  date?: string | null;
  files?: FileResponse[] | null;
  /** @format int64 */
  key?: number | null;
  /** @format int64 */
  personKey: number;
  personName?: string;
  /** The slug of the person */
  personSlug?: string;
  public: boolean;
  sourceUrl?: string | null;
  status: NILDealStatus;
  statusReason?: string | null;
  term: TermAmount;
}

export interface NilDealSubmissionResponsePagedData {
  list?: NilDealSubmissionResponse[];
  pagination?: Pagination;
}

export enum NilDealType {
  All = 'All',
  Person = 'Person',
  Organization = 'Organization',
}

export interface OrganizationResponse {
  abbreviation?: string | null;
  assetUrl?: string | null;
  defaultAsset?: AssetResponse;
  division?: string | null;
  fullName?: string;
  /** @format int32 */
  key: number;
  knownAs?: string | null;
  mascot?: string | null;
  name?: string;
  orgType?: string;
  orgTypeEnum: OrganizationType;
  primaryColor?: string | null;
  siteKeys?: number[] | null;
  slug?: string | null;
  urlSlug?: string | null;
}

export enum OrganizationType {
  Unknown = 'Unknown',
  HighSchool = 'HighSchool',
  JuniorCollege = 'JuniorCollege',
  College = 'College',
  Professional = 'Professional',
  PrepSchool = 'PrepSchool',
  Developmental = 'Developmental',
}

export interface OrganizationalNilDealRequest {
  /** @format int64 */
  agentKey?: number | null;
  /** @format double */
  amount?: number | null;
  /** @format int64 */
  collectiveGroupKey?: number | null;
  /** @format int64 */
  companyKey?: number | null;
  /** @format date-time */
  date?: string | null;
  detailUrl?: string | null;
  /** @format int64 */
  key?: number | null;
  /** @format int32 */
  orgKey: number;
  positions?: number[] | null;
  sourceUrl?: string | null;
  sports?: number[] | null;
  verified: boolean;
}

export interface Pagination {
  /** @format int32 */
  count: number;
  /** @format int32 */
  currentPage: number;
  /** @format int32 */
  itemsPerPage: number;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  /** @format int32 */
  pageCount: number;
}

export interface PlayerNilDealRequest {
  /** @format int64 */
  agentKey?: number | null;
  /** @format double */
  amount?: number | null;
  /** @format int64 */
  collectiveGroupKey?: number | null;
  /** @format int64 */
  companyKey?: number | null;
  /** @format date-time */
  date?: string | null;
  detailUrl?: string | null;
  /** @format int64 */
  key?: number | null;
  /** @format int64 */
  personKey: number;
  sourceUrl?: string | null;
  verified: boolean;
}

export interface PlayerResponse {
  /** @format int32 */
  age?: number | null;
  athleteVerified: boolean;
  classRank?: string | null;
  /** @format int32 */
  classYear?: number | null;
  /** @format date-time */
  dateOfBirth?: string | null;
  defaultAsset?: AssetResponse;
  defaultSport?: AttributeResponse;
  division?: string | null;
  earlyEnrollee: boolean;
  earlySignee: boolean;
  firstName?: string;
  fullName?: string;
  height?: string | null;
  highSchool?: OrganizationResponse;
  highSchoolName?: string | null;
  hometown?: AttributeResponse;
  /** @format int32 */
  jerseyNumber?: number | null;
  /** @format int64 */
  key: number;
  lastName?: string;
  position?: AttributeResponse;
  prospectVerified: boolean;
  /** @format int64 */
  recruitmentKey?: number | null;
  slug?: string;
  state?: AttributeResponse;
  /** @format double */
  weight?: number | null;
}

export interface PositionResponse {
  abbreviation?: string | null;
  /** @format int32 */
  key: number;
  name?: string;
  positionType: PositionType;
  sport?: SportResponseRecord;
  /** @format int32 */
  sportKey?: number | null;
}

export enum PositionType {
  Player = 'Player',
  Coach = 'Coach',
  Operations = 'Operations',
}

export interface ResponseMessage {
  message?: string;
  /** @format int32 */
  statusCode: number;
}

export interface SportResponseRecord {
  abbreviation?: string;
  isIndustryRankable: boolean;
  isRankable: boolean;
  /** @format int32 */
  key: number;
  name?: string;
}

export interface StringListResourceResponseMessage {
  message?: string;
  resource?: string[];
  /** @format int32 */
  statusCode: number;
}

export enum TermAmount {
  OneTime = 'OneTime',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Annually = 'Annually',
}

export interface UInt64StringKeyValuePair {
  /** @format int64 */
  key: number;
  value?: string;
}

export interface V2AdminDealsListParams {
  /**
   * The number to return in each page
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * The page number
   * @format int32
   * @default 1
   */
  page?: number;
  /** This is a filter that will filter based on All / Player / Organizational NIL deals */
  type?: NilDealType;
  /**
   * The year for a deal
   * @format int32
   */
  year?: number;
}

export interface V2AgentsAgenciesAutocompleteListParams {
  term?: string;
}

export interface V2AgentsAgenciesListParams {
  /**
   * @format int32
   * @min 1
   * @max 50
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 1
   */
  page?: number;
}

export interface V2AgentsAutocompleteListParams {
  term?: string;
}

export interface V2AgentsListParams {
  /**
   * @format int32
   * @min 1
   * @max 50
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 1
   */
  page?: number;
}

export interface V2AutocompleteNilCompaniesListParams {
  term?: string;
}

export interface V2DealsPlayersDetailParams {
  /**
   * The number to return in each page
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * The page number
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The person key for the player
   * @format int64
   */
  personKey: number;
}

export interface V2NilCompaniesListParams {
  /**
   * @format int32
   * @min 1
   * @max 50
   * @default 25
   */
  limit?: number;
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   * @default 1
   */
  page?: number;
}

export interface V2PlayersDealsDetailParams {
  /**
   * The number to return in each page
   * @format int32
   * @default 25
   */
  limit?: number;
  /**
   * The page number
   * @format int32
   * @default 1
   */
  page?: number;
  /**
   * The person key for the player
   * @format int64
   */
  personKey: number;
}

export type QueryParamsType = Record<string | number, any>;

export type RequestParams = Omit<
  AxiosRequestConfig,
  'data' | 'method' | 'params' | 'url' | 'responseType'
>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/**
 * No description
 *
 * @tags NilDeal
 * @name V2AdminDealsList
 * @summary Gets all NIL Deals
 * @request GET:/rdb/v2/deals
 * @secure
 */
export const v2AdminDealsList = (
  client: AxiosInstance,
  query: V2AdminDealsListParams,
  params: RequestParams = {},
) =>
  client
    .request<AdminNilDealResponsePagedData>({
      url: `/rdb/v2/deals`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags AgentControllerV
 * @name V2AgentsAgenciesAutocompleteList
 * @summary Autocomplete list of agencies based off of token
 * @request GET:/rdb/v2/agents/agencies/autocomplete
 * @secure
 */
export const v2AgentsAgenciesAutocompleteList = (
  client: AxiosInstance,
  query: V2AgentsAgenciesAutocompleteListParams,
  params: RequestParams = {},
) =>
  client
    .request<UInt64StringKeyValuePair[]>({
      url: `/rdb/v2/agents/agencies/autocomplete`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags AgentControllerV
 * @name V2AgentsAgenciesDelete
 * @summary Deletes key for an Agency
 * @request DELETE:/rdb/v2/agents/agencies/{agencyKey}
 * @secure
 */
export const v2AgentsAgenciesDelete = (
  client: AxiosInstance,
  agencyKey: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v2/agents/agencies/${agencyKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags AgentControllerV
 * @name V2AgentsAgenciesDetail
 * @summary Gets agent from agent key
 * @request GET:/rdb/v2/agents/agencies/{agencyKey}
 * @secure
 */
export const v2AgentsAgenciesDetail = (
  client: AxiosInstance,
  agencyKey: number,
  params: RequestParams = {},
) =>
  client
    .request<AgencyResponse>({
      url: `/rdb/v2/agents/agencies/${agencyKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags AgentControllerV
 * @name V2AgentsAgenciesList
 * @summary Gets all agencies
 * @request GET:/rdb/v2/agents/agencies
 * @secure
 */
export const v2AgentsAgenciesList = (
  client: AxiosInstance,
  query: V2AgentsAgenciesListParams,
  params: RequestParams = {},
) =>
  client
    .request<AgencyResponsePagedData>({
      url: `/rdb/v2/agents/agencies`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags AgentControllerV
 * @name V2AgentsAgenciesRenovateUpdate
 * @summary Updates or Adds an agency
 * @request PUT:/rdb/v2/agents/agencies/renovate
 * @secure
 */
export const v2AgentsAgenciesRenovateUpdate = (
  client: AxiosInstance,
  data: AgencyRequest,
  params: RequestParams = {},
) =>
  client
    .request<AgencyResponse[]>({
      url: `/rdb/v2/agents/agencies/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags AgentControllerV
 * @name V2AgentsAutocompleteList
 * @summary Autocomplete list of agents based off of token
 * @request GET:/rdb/v2/agents/autocomplete
 * @secure
 */
export const v2AgentsAutocompleteList = (
  client: AxiosInstance,
  query: V2AgentsAutocompleteListParams,
  params: RequestParams = {},
) =>
  client
    .request<AgentPersonResponse[]>({
      url: `/rdb/v2/agents/autocomplete`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags AgentControllerV
 * @name V2AgentsDelete
 * @summary Deletes Agent for a key
 * @request DELETE:/rdb/v2/agents/{agentKey}
 * @secure
 */
export const v2AgentsDelete = (
  client: AxiosInstance,
  agentKey: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v2/agents/${agentKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags AgentControllerV
 * @name V2AgentsDetail
 * @summary Gets agent from agent key
 * @request GET:/rdb/v2/agents/{agentKey}
 * @secure
 */
export const v2AgentsDetail = (
  client: AxiosInstance,
  agentKey: number,
  params: RequestParams = {},
) =>
  client
    .request<AgentPersonResponse>({
      url: `/rdb/v2/agents/${agentKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags AgentControllerV
 * @name V2AgentsList
 * @summary Gets all agents
 * @request GET:/rdb/v2/agents
 * @secure
 */
export const v2AgentsList = (
  client: AxiosInstance,
  query: V2AgentsListParams,
  params: RequestParams = {},
) =>
  client
    .request<AgentPersonResponsePagedData>({
      url: `/rdb/v2/agents`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags AgentControllerV
 * @name V2AgentsRenovateUpdate
 * @summary Updates or Adds an agent
 * @request PUT:/rdb/v2/agents/renovate
 * @secure
 */
export const v2AgentsRenovateUpdate = (
  client: AxiosInstance,
  data: AgentRequestV2,
  params: RequestParams = {},
) =>
  client
    .request<AgentPersonResponse[]>({
      url: `/rdb/v2/agents/renovate`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V2AutocompleteNilCompaniesList
 * @summary Autocomplete list of companies based off of token
 * @request GET:/rdb/v2/autocomplete-nil-companies
 * @secure
 */
export const v2AutocompleteNilCompaniesList = (
  client: AxiosInstance,
  query: V2AutocompleteNilCompaniesListParams,
  params: RequestParams = {},
) =>
  client
    .request<UInt64StringKeyValuePair[]>({
      url: `/rdb/v2/autocomplete-nil-companies`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V2DealsDelete
 * @summary Deletes NIL deal for a key
 * @request DELETE:/rdb/v2/deals/{dealKey}
 * @secure
 */
export const v2DealsDelete = (
  client: AxiosInstance,
  dealKey: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v2/deals/${dealKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V2DealsDetail
 * @summary Gets a Deal by key
 * @request GET:/rdb/v2/deals/{dealKey}
 * @secure
 */
export const v2DealsDetail = (
  client: AxiosInstance,
  dealKey: number,
  params: RequestParams = {},
) =>
  client
    .request<AdminNilDealResponse>({
      url: `/rdb/v2/deals/${dealKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V2DealsPlayersDetail
 * @summary Gets all NIL Deals for a player
 * @request GET:/rdb/v2/deals/players/{personKey}
 * @secure
 */
export const v2DealsPlayersDetail = (
  client: AxiosInstance,
  { personKey, ...query }: V2DealsPlayersDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<AdminNilDealResponsePagedData>({
      url: `/rdb/v2/deals/players/${personKey}`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V2DealsRenovateOrganizationDealUpdate
 * @summary Updates or Adds an NIL deal for a player
 * @request PUT:/rdb/v2/deals/renovate-organization-deal
 * @secure
 */
export const v2DealsRenovateOrganizationDealUpdate = (
  client: AxiosInstance,
  data: OrganizationalNilDealRequest,
  params: RequestParams = {},
) =>
  client
    .request<AdminNilDealResponse>({
      url: `/rdb/v2/deals/renovate-organization-deal`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V2DealsRenovatePlayerUpdate
 * @summary Updates or Adds an NIL deal for a player
 * @request PUT:/rdb/v2/deals/renovate-player
 * @secure
 */
export const v2DealsRenovatePlayerUpdate = (
  client: AxiosInstance,
  data: PlayerNilDealRequest,
  params: RequestParams = {},
) =>
  client
    .request<AdminNilDealResponse>({
      url: `/rdb/v2/deals/renovate-player`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboardController
 * @name V2DealsSubmissionsDelete
 * @summary  (Roles: registered user)
 * @request DELETE:/rdb/v2/deals/submissions/{dealKey}
 * @secure
 */
export const v2DealsSubmissionsDelete = (
  client: AxiosInstance,
  dealKey: number,
  params: RequestParams = {},
) =>
  client
    .request<DeleteResponse>({
      url: `/rdb/v2/deals/submissions/${dealKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDashboardController
 * @name V2DealsSubmissionsFilesDelete
 * @summary Deletes NIL Deal Submission file
 * @request DELETE:/rdb/v2/deals/submissions/{dealKey}/files/{fileKey}
 * @secure
 */
export const v2DealsSubmissionsFilesDelete = (
  client: AxiosInstance,
  dealKey: number,
  fileKey: string,
  params: RequestParams = {},
) =>
  client
    .request<NilDealSubmissionResponsePagedData>({
      url: `/rdb/v2/deals/submissions/${dealKey}/files/${fileKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V2NilCompaniesDelete
 * @summary Deletes NIL Partner Company for a key
 * @request DELETE:/rdb/v2/nil-companies/{companyKey}
 * @secure
 */
export const v2NilCompaniesDelete = (
  client: AxiosInstance,
  companyKey: number,
  params: RequestParams = {},
) =>
  client
    .request<void>({
      url: `/rdb/v2/nil-companies/${companyKey}`,
      method: 'DELETE',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V2NilCompaniesDetail
 * @summary  (Roles: developer, rdb2, rdb3, rdb4, rdb5)
 * @request GET:/rdb/v2/nil-companies/{companyKey}
 * @secure
 */
export const v2NilCompaniesDetail = (
  client: AxiosInstance,
  companyKey: number,
  params: RequestParams = {},
) =>
  client
    .request<NilCompanyAdminResponse>({
      url: `/rdb/v2/nil-companies/${companyKey}`,
      method: 'GET',
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V2NilCompaniesList
 * @summary Gets all Companies that have NIL deals
 * @request GET:/rdb/v2/nil-companies
 * @secure
 */
export const v2NilCompaniesList = (
  client: AxiosInstance,
  query: V2NilCompaniesListParams,
  params: RequestParams = {},
) =>
  client
    .request<NilCompanyAdminResponsePagedData>({
      url: `/rdb/v2/nil-companies`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V2PlayersDealsDetail
 * @summary Gets all NIL Deals for a player
 * @request GET:/rdb/v2/players/{personKey}/deals
 * @secure
 */
export const v2PlayersDealsDetail = (
  client: AxiosInstance,
  { personKey, ...query }: V2PlayersDealsDetailParams,
  params: RequestParams = {},
) =>
  client
    .request<AdminNilDealResponsePagedData>({
      url: `/rdb/v2/players/${personKey}/deals`,
      method: 'GET',
      params: query,
      ...params,
    })
    .then((r) => r.data);

/**
 * No description
 *
 * @tags NilDeal
 * @name V2RenovateNilCompaniesUpdate
 * @summary Updates or Adds a company to the companies with NIL deals
 * @request PUT:/rdb/v2/renovate-nil-companies
 * @secure
 */
export const v2RenovateNilCompaniesUpdate = (
  client: AxiosInstance,
  data: NilCompanyPartnerRequest,
  params: RequestParams = {},
) =>
  client
    .request<NilCompanyAdminResponse>({
      url: `/rdb/v2/renovate-nil-companies`,
      method: 'PUT',
      data,
      ...params,
    })
    .then((r) => r.data);
